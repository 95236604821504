<template>



    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

                <p class="text-size-64" style="color: rgb(0, 0, 0);
                    
                    

                    
                    
                    text-align: left;">
                    惯性测量单元

                </p>

                <h1 class="text-size-96" style="color: rgb(0, 0, 0);
                    
                    font-weight: bold;
                    text-wrap: nowrap;
                    text-align: left;">
                    CXGY-110 IMU
                </h1>


                <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                    CXGY-110 IMU是一款集成三轴陀螺和三轴加速度计的高精度惯性测量单元。产品经过全温范围
                    标定，采用铝合金外壳，为苛刻的应用场景提供可靠的惯性测量性能。适用于机器人、无人车、小型无人机导航控制、稳定平台、卫星组 合导航等领域。
                </p>


            </div>






        </div>

    </div>


    <div v-show="isMobile" class="h-c">
        <div class="div-spacer-h288"></div>
        <div class="v-c">

            <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                CXGY-110 IMU是一款集成三轴陀螺和三轴加速度计的高精度惯性测量单元。产品经过全温范围
                标定，采用铝合金外壳，为苛刻的应用场景提供可靠的惯性测量性能。适用于机器人、无人车、小型无人机导航控制、稳定平台、卫星组 合导航等领域。
            </p>
</div>
        <div class="div-spacer-h288"></div>
    </div>    <div v-show="!isMobile" class="div-spacer-h288"></div>





    <div class="h-c">

        <CombinedTitle title="惯性测量单元" shortTitle="" subTitle="CXGY-110 IMU" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/工规产品/cxgy-110 IMU/CXGY-110 IMU.1.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                
                    



                    
                    
                    text-align: left;">
                    产品构成
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">





                    <li>三轴加速度计 （加计）</li>
                    <li>三轴角速度计 （陀螺）</li>

                </ul>

            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/工规产品/cxgy-110 IMU/CXGY-110 IMU.2.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    产品特点
                </h3>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    font-weight: bold;
                    
                    text-align: left;">
                    本产品具有体积小、环境适应 性强、低功耗、时间同步等特点。
                </p>

                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    


                    
                    text-align: left;">






                    <li>适配机器人/机器狗全场景步态</li>
                    <li>阵列IMU</li>
                    <li>全温标定（Step:10°C）</li>
                    <li>角速率测量范围：±300°/s</li>
                    <li>加速度计测量范围：±6g</li>
                    <li>工作温度范围：−40°C～+110°C</li>
                    <li>高精度：陀螺零偏不稳定性小于1.8°/h</li>
                    <li>加速度计零偏不稳定性小于15μg</li>
                    <li>车规级器件满足 AEC-Q100 认证</li>
                    <li>符合 ISO26262 ASIL-B 功能安全设计</li>
                    <li>经过全温标定</li>
                </ul>



            </div>


        </div>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <p class="text-size-64" style="text-align: center;
            color: rgb(0, 0, 0);
            
            
            font-weight: bold;

            
            text-align: center;">
            产品性能参数
        </p>

    </div>

    <div class="div-spacer-h80"></div>




    <div class="h-c">
        <div class="v-c">

            <SpecTable :specData="[
  {
    key: '陀螺指标',
    tableData: [
      {
        title: '量程',
        contentList: ['标称值：±300', '标定后：±300', '单位：°/s']
      },
      {
        title: '全温零偏',
        contentList: ['标称值：≤ 1(XY) ≤0.5(Z)', '标定后：≤0.1', '单位：°/s']
      },
      {
        title: '零偏不稳定性(Allan 方差)',
        contentList: ['标称值：1.8(XY) 1.4(Z)', '标定后：1.8(XY)1.4(Z)', '单位：°/h']
      }
    ]
  },
  {
    key: '加速度计指标',
    tableData: [
      {
        title: '量程',
        contentList: ['标称值：±6', '标定后：±6', '单位：g']
      },
      {
        title: '全温零偏',
        contentList: ['标称值：≤20(XY) ≤30(Z)', '标定后：≤ 1', '单位：mg']
      },
      {
        title: '零偏不稳定性(Allan 方差)',
        contentList: ['标称值：15', '标定后：15', '单位：μg']
      }
    ]
  }
]" />

        </div>
    </div>


    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c" style="justify-content: center;">
            <router-link to="/about/contact" style="text-align: center; width: 100%;">
        <AdapButtomBlack text="获取详细产品信息" />
      </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            font-weight: bold;
            text-align: center;">
            应用场景
        </h3>


    </div>
    <div class="div-spacer-h80"></div>


    <div class="h-c">

        <div v-show="!isMobile" class="v-c">
            <ScrollingCards :items="items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards :items="items" />
        </div>





    </div>


    <div class="div-spacer-h288"></div>





</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner工规产品/科技正圆+cxgy110.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}





</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards from '@/components/ScrollingCards.vue';
import mScrollingCards from '@/components/mScrollingCards.vue';
const items = [
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/无人车4.jpg'), title: '低速无人车', url: '/solution/low-speed-vehicle' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/机器人.jpg'), title: '智能机器人', url: '/solution/smart-robot' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/矿区2.jpg'), title: '工程机械自动化', url: '/solution/engineering-machinery' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/智慧农机.jpg'), title: '自动化农机', url: '/solution/agricultural-machinery' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/无人机3.jpg'), title: '无人飞行器', url: '/solution/uav' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/港口.jpg'), title: '港口船舶', url: '/solution/port-shipping' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/自动驾驶.jpg'), title: '智能驾驶汽车', url: '/solution/smart-driving' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/测绘1.jpg'), title: '智慧测绘仪器', url: '/solution/smart-surveying' },

];import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>