<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 style="color: rgb(255, 255, 255);
                
                
                font-weight: bold;;
                
                
                text-align: left;" class="text-size-72">

                    行业解决方案

                </h1>

                <div class="div-spacer-h16"></div>
                <p style="color: rgb(255, 255, 255);
                
                
                
                
                
                text-align: left;" class="text-size-24">

                    行业场景高精定位定制化解决方案融合了卫星定位技术、惯性定位技术、视觉感知技术、激光雷达技术。为各行业定位需求提供稳定、高效、高精度的信息服务。

                </p>


            </div>





        </div>

    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/低速无人车/无人车2.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    低速无人车
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    无人车行业，也称为无人驾驶汽车行业或自动驾驶汽车行业，
                    是智能汽车发展的高级形态，无人车的应用场景广泛，包括物流配送、
                    城市出租、旅游客运、智能公交、工业运输、医疗运输、环卫清洁等多个领域。
                    随着技术的不断成熟和政策的持续推动，无人驾驶汽车已逐步从研发阶段走向商业化应用。
                </p>
            </div>

        </div>
    </div>
    <div class="div-spacer-h120"></div>
    <div class="div-spacer-h120"></div>

    <div class="h-c">
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: center;">

                    低速无人车-行业痛点

                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">

                    无人驾驶车，需要厘米级的全域持续定位，而纯使用视觉/激光SLAM，
                    对环境的敏感性较高，数据处理的复杂度高，且成本高昂。IMU / INS惯性组合定位依靠传感器自身数据，
                    不受环境影响，且成本较低，能很好的补充SLAM的不足，提升定位感知系统的鲁棒性和定位精度。


                </p>

            </div>

        </div>

    </div>

    <div class="div-spacer-h120"></div>
    <div class="div-spacer-h120"></div>


    <div class="h-c">
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/低速无人车/无人车.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    产品优势
                </h3>












                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    
                    
                    text-align: left;
                    list-style-position: inside;">
                    <li>国内首家L4级别道路级无人配送车量产出货</li>
                    <li>车规级器件</li>
                    <li>高性价比</li>
                    <li>高可靠性</li>
                    <li>算法带来高精度输出</li>
                    <li>全面的技术支持服务</li>
                </ul>
            </div>

        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            
            
            
            
            
            text-align: center;">


            相关产品

        </h3>

    </div>
    <div class="div-spacer-h48"></div>

    <div class="h-c">


        <RelatedScrollingCards3 :products="RelatedScrollingCards3Products" :visibleImages="2" />

    </div>
    <div class="div-spacer-h288"></div>






</template>



<style scoped>




.banner {

    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("@/assets/images/官网开发素材/banner/行业解决方案banner/无人车4.jpg");
    z-index: -1;


    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果

import PictureCard from '@/components/PictureCard.vue';
import RelatedScrollingCards3 from '@/components/RelatedScrollingCards3.vue';
const RelatedScrollingCards3Products = [
  {
    imageUrl: require('@/assets/images/官网开发素材/工规产品/cxgy-10 IMU/CXGY-10 IMU.jpg'),
    title: "CXGY-10 IMU",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy10"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-20 VRU/CXGY-20 VRU2.jpg"),
    title: "CXGY-20 VRU",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy20"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-30 AHRS/CXGY-30 AHRS.17.jpg"),
    title: "CXGY-30 AHRS",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy30"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-50 INS-BOX/CXGY-50 INS-BOX双天线pbox.5.jpg"),
    title: "CXGY-50 INS-BOX",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy50"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/cxgy-110 IMU/CXGY-110 IMU.1.jpg"),
    title: "CXGY-110 IMU",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy110"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-150 INS-BOX双天线/CXGY-150 INS-BOX双天线pbox.3.jpg"),
    title: "CXGY-150 INS-BOX",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy150"
  }
]




</script>