<template>
  <div id="app">
    <div style="height: 56px;"></div>
    <Nav2 />
    <!-- 主要内容区域 -->
    <main>
      <router-view />
    </main>
    <!-- 底部工具 -->
    <Footer v-show="!isMobile" />
    <FooterM v-show="isMobile" />
    <ToUp />
  </div>
</template>

<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果




import Nav2 from './components/Nav2.vue'
import ToUp from './components/ToUp.vue';
import Footer from './components/Footer.vue';
import FooterM from './components/FooterM.vue';

// 根据宽度判断手机端
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
// 根据宽度判断手机端


</script>

<style>

/* 添加动画相关属性 */
.h-c {      
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);

}
.h-c.appear {
  opacity: 1;
  transform: translateY(0);
}
/* 添加动画相关属性 */


#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;




}

.banner {
  height: calc(100vh - 56px);
  width: 100%;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.02);
}



/* 横向占位 */
.h-c {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* 视区容器 */
.v-c {
  gap: 40px;
  width: 100%;
  max-width: 1200px;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}


.g-c-4-2-2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.g-c-3-1-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.g-c-3-3-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}


.g-c-4-2-1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}


.g-c-2-1-1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}


.g-c-5-1-1 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
}


@media (max-width: 1200px) {
  .g-c-4-2-1 {
    grid-template-columns: repeat(2, 1fr);
  }

  .g-c-4-2-2 {
    grid-template-columns: repeat(2, 1fr);
  }


  .v-c.m1024 {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0px 28px;
    text-align: center !important;
    justify-content: center;

  }

  .g-c-3-1-1 {
    grid-template-columns: repeat(1, 1fr);

  }



}

/* 手机端适配 */
@media (max-width: 768px) {
  .v-c {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0px 28px;
    text-align: center;
    justify-content: center;

  }

  .rev {
    flex-direction: column-reverse;
  }

  .banner {
    max-height: 319px !important;
    /* padding: 0px 28px; */

  }

  .g-c-4-2-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .g-c-4-2-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  .g-c-2-1-1 {
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 16px;

  }

  .g-c-5-1-1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }

  .g-c-3-3-1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .PictureCard img {
    min-width: 100% !important;
    height: auto !important;

  }

  .PictureCard {

    height: auto !important;
    max-width: 100% !important;

  }

  /* 手机端的段落顶到两边 */
  div:has(p):has(h3) {
    max-width: 100% !important;
  }

  div:has(ul):has(h3) {
    max-width: 100% !important
  }

  /* 选择div中有h3 且只有一个h3 且有p的 */
  div:has(h3):not(:has(h3 ~ h3)):has(p) {
    /* background-color: aqua; */
    gap: 4px !important;

  }

  /* 选择上述div中的h3元素 */
  div:has(h3):not(:has(h3 ~ h3)):has(p)>h3 {
    /* 这里添加h3的样式 */
    margin-top: 16px;
  }

  /* 选择div中有h3 且只有一个h3 且有ul的 */
  div:has(h3):not(:has(h3 ~ h3)):has(ul) {
    /* background-color: aqua; */
    gap: 4px !important;

  }

  /* 选择上述div中的h3元素 */
  div:has(h3):not(:has(h3 ~ h3)):has(ul)>h3 {
    /* 这里添加h3的样式 */
    margin-top: 16px;
  }

  /* 无名类定义规范
  1.只有banner可以使用h1
  2.只有组件内可以使用h2
  3.所有其他标题统一使用h3 */





}




/* 设置主要内容区的样式，使其内容不会被导航栏遮挡 */
main {
  z-index: 1;
  /* 确保主要内容位于导航之下 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}


footer {
  width: 100%;
}


/* 如果需要给导航添加透明度或者其他样式，可以在这里定义 */
header {
  position: absolute;
  width: 100%;
  z-index: 2;
  /* 确保导航位于主要内容之上 */
  /* 可能还需要设置背景颜色或其他样式，确保可读性 */
}
</style>