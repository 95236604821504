<template>



    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

                <p class="text-size-64" style="color: rgb(0, 0, 0);
                    
                    

                    
                    
                    text-align: left;">
                    单天线<br>
                    高精定位整机
                </p>

                <h1 class="text-size-96" style="color: rgb(0, 0, 0);
                    
                    font-weight: bold;
                    text-wrap: nowrap;
                    text-align: left;">
                    P21S
                </h1>


                <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                    P21S高精度组合导航系统基于GNSS高精度芯片，搭载了满足功能安全 ASIL-B 等级要求的高精度IMU，支持全系统双频点，配备车载以太网、串口、CAN/CAN FD 和PPS接口。
                </p>


            </div>






        </div>

    </div>


    <div v-show="isMobile" class="h-c">
        <div class="div-spacer-h288"></div>
        <div class="v-c">
            
            <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                P21S高精度组合导航系统基于GNSS高精度芯片，搭载了满足功能安全 ASIL-B 等级要求的高精度IMU，支持全系统双频点，配备车载以太网、串口、CAN/CAN FD 和PPS接口。
            </p>
</div>
        <div class="div-spacer-h288"></div>
    </div>    <div v-show="!isMobile" class="div-spacer-h288"></div>





    <div class="h-c">

        <CombinedTitle title="单天线P-BOX(乘用车)" shortTitle="" subTitle="Single-Antenna P-BOX (Passenger Vehicles)" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/整机/P21S/P21S-7.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    




                    











                    
                    
                    text-align: left;">
                    产品构成
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">

                    <li>GNSS（全球卫星定位系统）</li>
                    <li>RTK（实时动态载波相位差分技术）</li>
                    <li>IMU （惯性测量单元）</li>
                    <li>ETH （网络单元）</li>
                    <li>接口子系统（RS232/422，CAN）</li>
                </ul>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    font-weight: bold;
                    
                    text-align: left;">
                    适用于经济型车型以及多行业应用，采用单天线，易于前期测试集成度高，开发周期短。
                </p>
            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/整机/P21S/P21S-8.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    产品特点
                </h3>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">
                    兼具卫星导航系统GNSS与惯性IMU优势，精度高、可靠性强。
                </p>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    












                    
                    text-align: left;">

                    <li>支持全系统全频GNSS信号接收</li>
                    <li>支持松/紧耦合组合导航算法</li>
                    <li>接口扩展性：车载以太网、CAN/CANFD, RS232、PPS</li>
                    <li>满足功能安全ASIL-B</li>
                </ul>



            </div>


        </div>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <p class="text-size-64" style="text-align: center;
            color: rgb(0, 0, 0);
            
            
            font-weight: bold;

            
            text-align: center;">
            产品性能参数
        </p>

    </div>

    <div class="div-spacer-h80"></div>




    <div class="h-c">
        <div class="v-c">

            <SpecTable :specData="[
                
                {
                    key: '规格指标',
                    tableData: [
                        {
                            title: '系统',
                            contentList: ['全系统: GPS、BDS、GLO、GAL、QZSS']
                        },
                        {
                            title: '信号频段',
                            contentList: [
                                'BDS B1I, B1C*, B2a',
                                'GPS L1C/A, L5',
                                'GLONASS G1*',
                                'Galileo E1, E5a',
                                'QZSS L1, L5'
                            ]
                        },
                        {
                            title: '对外接口',
                            contentList: [
                                'UART串口×1',
                                'CAN/CAN FD总线×2',
                                '车载以太网100Mbps×1',
                                'PPS×1'
                            ]
                        },
                        {
                            title: '水平定位精度',
                            contentList: [
                                '单点 <1.5 m',
                                'RTK 1cm+1ppm'
                            ]
                        },
                        {
                            title: '高程定位精度',
                            contentList: [
                                '单点 <4.5m',
                                'RTK 2cm+1ppm'
                            ]
                        },
                        {
                            title: '输出频率',
                            contentList: [
                                'GNSS数据更新频率 10HZ',
                                'INS组合导航定位 100HZ',
                                'IMU数据 100HZ'
                            ]
                        },
                        {
                            title: '首次定位时间',
                            contentList: [
                                '冷启动<35s',
                                '热启动<3s'
                            ]
                        },
                        {
                            title: 'RTK初始化时间',
                            contentList: ['<10s']
                        },
                        {
                            title: '失锁重捕获时间',
                            contentList: ['<2s']
                        },
                        {
                            title: 'RTK解算延迟',
                            contentList: ['<100ms']
                        },
                        {
                            title: '授时精度（RMS）',
                            contentList: ['20ns']
                        },
                        {
                            title: '速度精度',
                            contentList: ['0.05 m/s']
                        }
                    ]
                },
                {
                    key: '物理和电气',
                    tableData: [
                        {
                            title: '尺寸',
                            contentList: ['118.5*79.5*28.5mm(不含连接器)']
                        },
                        {
                            title: '重量(g)',
                            contentList: ['250±30g']
                        },
                        {
                            title: '输入电压',
                            contentList: ['9.0V～16.0V']
                        },
                        {
                            title: '功耗',
                            contentList: ['3W']
                        },
                        {
                            title: '最大电流',
                            contentList: ['300mA']
                        },
                        {
                            title: '主连接器接口',
                            contentList: ['JAE MX23A18NF1']
                        },
                        {
                            title: '射频连接器',
                            contentList: ['TE 2291392-3']
                        },
                        {
                            title: '以太连接器',
                            contentList: ['HRB A-M22055-2-A']
                        }
                    ]
                },
                {
                    key: '陀螺仪',
                    tableData: [
                        {
                            title: '量程',
                            contentList: ['±250（125/250/500/1000）°/S']
                        },
                        {
                            title: '零偏不稳定性',
                            contentList: ['≤3°/h']
                        },
                        {
                            title: '角度随机游走',
                            contentList: ['≤0.21°/√h']
                        },
                        {
                            title: '速度随机游走',
                            contentList: ['/']
                        }
                    ]
                },
                {
                    key: '加速度计',
                    tableData: [
                        {
                            title: '量程',
                            contentList: ['±4（2/4/8/16）g']
                        },
                        {
                            title: '零偏不稳定性',
                            contentList: ['≤0.05mg']
                        },
                        {
                            title: '角度随机游走',
                            contentList: ['/']
                        },
                        {
                            title: '速度随机游走',
                            contentList: ['≤0.04m/s/√hr']
                        }
                    ]
                },
                {
                    key: '环境及其他',
                    tableData: [
                        {
                            title: '工作温度',
                            contentList: ['-40℃～105℃']
                        },
                        {
                            title: '储存温度',
                            contentList: ['-55℃～125℃']
                        },
                        {
                            title: '湿度',
                            contentList: ['95%非凝露']
                        },
                        {
                            title: '防尘防水等级',
                            contentList: ['IP54']
                        },
                        {
                            title: '振动',
                            contentList: ['ISO 16750-3-2023']
                        }
                    ]
                },
                {
                    key: '组合导航性能指标',
                    tableData: [
                        {
                            title: '航向精度',
                            contentList: ['0.2°@(1km or 2min)2σ']
                        },
                        {
                            title: '速度精度',
                            contentList: ['0.1m/s 2σ']
                        },
                        {
                            title: '位置精度',
                            contentList: ['2‰D @(1km or 2min)2σ']
                        }
                    ]
                }
                
            ]" />

        </div>
    </div>
<!-- 

    <div class="div-spacer-h80"></div>
    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <p class="text-size-64" style="text-align: center;
            color: rgb(0, 0, 0);
            
            
            font-weight: bold;

            
            text-align: center;">
            陀螺仪加计性能参数
        </p>

    </div>

    <div class="div-spacer-h80"></div>




    <div class="h-c">
        <div class="v-c">

            <SpecTable :specData="[
                {
                    key: '陀螺仪',
                    tableData: [
                        {
                            title: '量程',
                            contentList: ['±250（125/250/500/1000）°/S']
                        },
                        {
                            title: '零偏不稳定性',
                            contentList: ['≤3°/h']
                        },
                        {
                            title: '角度随机游走',
                            contentList: ['≤0.21°/√h']
                        },
                        {
                            title: '速度随机游走',
                            contentList: ['/']
                        }
                    ]
                },
                {
                    key: '加速度计',
                    tableData: [
                        {
                            title: '量程',
                            contentList: ['±4（2/4/8/16）g']
                        },
                        {
                            title: '零偏不稳定性',
                            contentList: ['≤0.05mg']
                        },
                        {
                            title: '角度随机游走',
                            contentList: ['/']
                        },
                        {
                            title: '速度随机游走',
                            contentList: ['≤0.04m/s/√hr']
                        }
                    ]
                }
            ]" />

        </div>
    </div> -->

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c" style="justify-content: center;">
            <router-link to="/about/contact" style="text-align: center; width: 100%;">
        <AdapButtomBlack text="获取详细产品信息" />
      </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            font-weight: bold;
            text-align: center;">
            应用场景
        </h3>


    </div>
    <div class="div-spacer-h80"></div>


    <div class="h-c">

        <div v-show="!isMobile" class="v-c">
            <ScrollingCards2 :items="ScrollingCards2_items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards2 :items="ScrollingCards2_items" />
        </div>





    </div>


    <div class="div-spacer-h288"></div>





</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner车规产品/科技正圆+P21S.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */



}





</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards2 from '@/components/ScrollingCards2.vue';
import mScrollingCards2 from '@/components/mScrollingCards2.vue';
const ScrollingCards2_items = [
    { image: require('@/assets/images/官网开发素材/inertia/p2.jpg'), title: '隧道内岔路口', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p3.jpg'), title: '高架桥下', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p4.jpg'), title: '地下停车场', desc: '无卫星信号' },
    { image: require('@/assets/images/官网开发素材/inertia/p6.jpg'), title: '城市峡谷', desc: '卫星信号弱，多路劲效应影响' },
    { image: require('@/assets/images/官网开发素材/inertia/p5.jpg'), title: '复杂立体交通', desc: '卫星信号无/弱' }
]; import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import FeatureCard from '@/components/FeatureCard.vue';
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtom from '@/components/AdapButtom.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>