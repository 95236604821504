<!-- 产品服务-软件服务 -->

<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 936px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255);
                
                
                
                
                
                text-align: left;">

                    聚焦“智能座舱&网联”<br>
                    提供软件系统一体化解决方案

                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(255, 255, 255);
                
                
                
                
                
                text-align: left;">

                    诚芯智联具备车载鸿蒙OS、安卓等多平台相关开发服务业务能力，
                    移植智能驾舱、自适应Auto-SAR、域控制器、SOA等项目经验和能力到鸿蒙系统，
                    以及Camera相关的感知融合层面的开发服务业务。

                </p>


            </div>





        </div>

    </div>

    <div class="div-spacer-h288"></div>



    <div class="h-c">
        <div class="v-c">
            <h3 class="text-size-64" style="color: rgb(0, 0, 0);
        
        
        
        
        
        text-align: center;">

            软件定义未来交通工具

        </h3>
        </div>
        
    </div>

    <div class="div-spacer-h16"></div>
    <div class="div-spacer-h16"></div>

    <div class="h-c">
        <div class="v-c">
            <h3 class="text-size-20" style="color: rgb(0, 0, 0);
        
        
        
        
        
        text-align: center;">

            围绕用户用车场景，构建个性化、沉浸式、主动服务的全新智能驾舱体验

        </h3>
        </div>
        
    </div>


    <div class="div-spacer-h80"></div>

    <div class="h-c" >
        <div class="v-c">
            <PictureCardAmplifier :src="require('@/assets/images/官网开发素材/software/p1.jpg')" :radius="15"
                style="max-width: 621px;height: 351px;" />
            <div
                style="display: flex;flex-direction: column;justify-content: left;gap: 16px;padding: 0px;max-width: 474px;">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                ">
                    提供软件系统一体化解决方案
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                ">
                    为车企或Tire1提供一体化解决方案与核心板定制化服务（Linux、Android、openharmony等）
                </p>
                <router-link to="/about/contact">
                    <AdapButtomBlack class="abb" text="联系我们" />
                </router-link>
                

            </div>
        </div>
    </div>

    <div class="div-spacer-h120"></div>

    <div class="h-c">
        <div class="v-c sec">
            
            <div
                style="display: flex;flex-direction: column;justify-content: left;gap: 16px;max-width: 474px;">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                ">
                    匹配车厂项目需求进行定制化服务
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                ">
                    软件系统及特性定制开发服务，满足车厂个性化需求。基础硬件以上代码开放，车厂完全自主可控。
                </p>
                <router-link to="/about/contact">
                    <AdapButtomBlack class="abb" text="联系我们" />
                </router-link>
                

            </div>
            <PictureCardAmplifier :src="require('@/assets/images/官网开发素材/software/p2.jpg')" :radius="15"
                style="max-width: 621px;height: 351px;" />
            
        </div>
    </div>





    <div class="div-spacer-h288"></div>






</template>



<style scoped>
a{
    width: fit-content;
}



.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner-product-software.jpg");
    
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}

@media (max-width: 768px) {

    .PictureCardAmplifier{
        
        height: 220px !important;
    }

    a{
        margin-top: 20px;
        align-self: center;
    }

    p{
        margin-top: 12px;
    }

    .abb{
        align-self: center;

    }
    

    .sec {
        flex-direction: column-reverse;
    }
}




</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCardAmplifier from '@/components/PictureCardAmplifier.vue';

const items = [
    { image: require('@/assets/images/官网开发素材/车规产品/imubox蓝色背景.jpg'), title: 'Title 1', description: 'Description 1' },
    { image: require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=成本低.svg'), title: 'Title 2', description: 'Description 2' },
    { image: require('@/assets/images/官网开发素材/行业解决方案/自动驾驶汽车/车1.jpg'), title: 'Title 3', description: 'Description 3' },
];

</script>