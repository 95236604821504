<template>



    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

                <p class="text-size-64" style="color: rgb(0, 0, 0);
                    
                    

                    
                    
                    text-align: left;">
                    车载精准定位终端

                </p>

                <h1 class="text-size-96" style="color: rgb(0, 0, 0);
                    
                    font-weight: bold;
                    text-wrap: nowrap;
                    text-align: left;">
                    CXGY-B120
                </h1>


                <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                    CXGY-B120移动机械远程监控终端，支持单北斗定位，可跟踪处理北斗导航系统的全部信号频点。支持工作时长统计，并可实现远程运维管理。
                </p>


            </div>






        </div>

    </div>


    <div v-show="isMobile" class="h-c">
        <div class="div-spacer-h288"></div>
        <div class="v-c">

            <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                CXGY-B120移动机械远程监控终端，支持单北斗定位，可跟踪处理北斗导航系统的全部信号频点。支持工作时长统计，并可实现远程运维管理。
            </p>
</div>
        <div class="div-spacer-h288"></div>
    </div>    <div v-show="!isMobile" class="div-spacer-h288"></div>





    <div class="h-c">

        <CombinedTitle title="车载精准定位终端" shortTitle="" subTitle="CXGY-B120" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/工规产品/CXBSD121SA/车载定位终端.7.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                
                    



                    
                    
                    text-align: left;">
                    产品构成
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">





                    <li>BDS（全频北斗定位系统）</li>
                    <li>电源电池</li>
                    <li>4G</li>
                </ul>

            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/工规产品/CXBSD121SA/车载定位终端.9.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    产品特点
                </h3>


                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    


                    
                    text-align: left;">




                    <li>支持单北斗高精度定位、支持盲区数据补存</li>
                    <li>CAN数据上传，OBD诊断和告警上报</li>
                    <li>支持OTA远程升级</li>
                    <li>适用于各种移动机械、车辆的远程监控管理</li>
                </ul>



            </div>


        </div>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <p class="text-size-64" style="text-align: center;
            color: rgb(0, 0, 0);
            
            
            font-weight: bold;

            
            text-align: center;">
            产品性能参数
        </p>

    </div>

    <div class="div-spacer-h80"></div>




    <div class="h-c">
        <div class="v-c">

            <SpecTable :specData="[
                {
                    key: '性能指标',
                    tableData: [
                        {
                            title: '频点',
                            contentList: ['BDS: B1I、B1C、B2I、B2a、B2b、B3I']
                        },
                        {
                            title: 'TTFF',
                            contentList: [
                                '冷启动：30S',
                                '热启动：2S',
                                'RTK初始化间：<5S'
                            ]
                        },
                        {
                            title: '水平定位精度 (1σ)',
                            contentList: [
                                '单点定位：1.5M',
                                'RTD 精度：0.4M',
                                'RTK 精度：0.7CM+1PPM'
                            ]
                        },
                        {
                            title: '高程定位精度 (1σ)',
                            contentList: [
                                '单点定位：2.5M',
                                'RTD 精度：0.8M',
                                'RTK 精度：1.5CM+1PPM'
                            ]
                        }
                    ]
                }
            ]" />

        </div>
    </div>


    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c" style="justify-content: center;">
            <router-link to="/about/contact" style="text-align: center; width: 100%;">
        <AdapButtomBlack text="获取详细产品信息" />
      </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            font-weight: bold;
            text-align: center;">
            应用场景
        </h3>


    </div>
    <div class="div-spacer-h80"></div>


    <div class="h-c">

        <div v-show="!isMobile" class="v-c">
            <ScrollingCards :items="items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards :items="items" />
        </div>





    </div>


    <div class="div-spacer-h288"></div>





</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner工规产品/科技正圆+CXBSD121SA.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}





</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards from '@/components/ScrollingCards.vue';
import mScrollingCards from '@/components/mScrollingCards.vue';
const items = [
    { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/无人车4.jpg'), title: '低速无人车', url: '/solution/low-speed-vehicle' },
    { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/机器人.jpg'), title: '智能机器人', url: '/solution/smart-robot' },
    { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/矿区2.jpg'), title: '工程机械自动化', url: '/solution/engineering-machinery' },
    { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/智慧农机.jpg'), title: '自动化农机', url: '/solution/agricultural-machinery' },
    { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/无人机3.jpg'), title: '无人飞行器', url: '/solution/uav' },
    { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/港口.jpg'), title: '港口船舶', url: '/solution/port-shipping' },
    { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/自动驾驶.jpg'), title: '智能驾驶汽车', url: '/solution/smart-driving' },
    { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/测绘1.jpg'), title: '智慧测绘仪器', url: '/solution/smart-surveying' },

]; import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>