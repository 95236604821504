<template>



    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

                <p class="text-size-64" style="color: rgb(0, 0, 0);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">
                    惯性测量单元
                </p>

                <h1 class="text-size-96" style="color: rgb(0, 0, 0);
                    
                    font-weight: bold;
                    text-wrap: nowrap;
                    text-align: left;">
                    IM13-M
                </h1>


                <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                    IM13-M集成三轴陀螺和三轴加速度计的车规级通用性惯性测量单元。产品经过全温范围标定，采用铝合金外壳，为苛刻的应用场景提供可靠的惯性测量性能。本产品具有体积小、环境适应性强、低功耗、精度高、稳定性好等特点。
                </p>


            </div>






        </div>

    </div>


    <div v-show="isMobile" class="h-c">
        <div class="div-spacer-h288"></div>
        <div class="v-c">

            <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                IM13-M集成三轴陀螺和三轴加速度计的车规级通用性惯性测量单元。产品经过全温范围标定，采用铝合金外壳，为苛刻的应用场景提供可靠的惯性测量性能。本产品具有体积小、环境适应性强、低功耗、精度高、稳定性好等特点。
            </p>
        </div>
        <div class="div-spacer-h288"></div>
    </div>
    <div v-show="!isMobile" class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="text-align: center;color: rgb(0, 0, 0);
            
            
            
            
            
            text-align: center;">
            选择产品类型
        </h3>
    </div>
    <div class="div-spacer-h60"></div>

    <div class="h-c">
        <div class="v-c dfsghtr5" style="max-width: 750px">
            <router-link to="/product/automotive/imu/im11">
                <AdapButtom text='IM11' />
            </router-link>
            <router-link to="/product/automotive/imu/im12">
                <AdapButtom text='IM12' />
            </router-link>
            <router-link to="/product/automotive/imu/im13">
                <AdapButtom text='IM13' />
            </router-link>
            <router-link to="/product/automotive/imu/im15">
                <AdapButtom text='IM15(板对板)' />
            </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">

        <CombinedTitle title="IM13 / IM13-M" shortTitle="（MCU）" subTitle="IMU 13 / IM13-MCU" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/模组/IM13/IM13-1.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    产品构成
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">

                    <li>三轴加速度计 （加计）</li>
                    <li>三轴角速度计 （陀螺）</li>
                    <li>阵列IMU （2/4/8）</li>
                </ul>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    
                    font-weight: bold;
                    text-align: left;">
                    IMU系列模组采用不锈钢外壳，为苛刻的应用场景提供可靠的惯性测量性能。
                </p>
            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/模组/IM13/IM13-2.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    产品特点
                </h3>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    font-weight: bold;
                    
                    text-align: left;">
                    本产品全温标定，易于前期测试集成度高，开发周期短，体积小、环境适应性强、低功耗、精度高、稳定性好等特点。
                </p>
                <div style="display: grid;grid-template-columns: 1fr 1fr;gap: 16px;">


                    <FeatureCard title="高适配" describe="适配度高，运用广泛" />
                    <FeatureCard title="强适应" describe="环境适应性强，可靠性强" />
                    <FeatureCard title="小型化" describe="超小体积，低功耗" />
                    <FeatureCard title="优性能" describe="高精度、稳定性好" />

                </div>


            </div>


        </div>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <p class="text-size-64" style="text-align: center;
            color: rgb(0, 0, 0);
            
            
            font-weight: bold;

            
            text-align: center;">
            产品性能参数
        </p>

    </div>

    <div class="div-spacer-h80"></div>




    <div class="h-c">
        <div class="v-c">
            <SpecTable :specData="[
                {
                    key: '物理和电气',
                    tableData: [
                        {
                            title: '尺寸',
                            contentList: ['22.4*22.4*9.5mm']
                        },
                        {
                            title: '重量',
                            contentList: ['13±2g']
                        },
                        {
                            title: '输入电压',
                            contentList: ['5V']
                        },
                        {
                            title: '功耗',
                            contentList: ['<100 mw']
                        },
                        {
                            title: '主连接器接口',
                            contentList: ['MOLEX 5015680607']
                        },
                        {
                            title: '对外接口',
                            contentList: ['UART']
                        }
                    ]
                },
                {
                    key: '环境及其他',
                    tableData: [
                        {
                            title: '工作温度',
                            contentList: ['-40℃～105℃']
                        },
                        {
                            title: '储存温度',
                            contentList: ['-55℃～125℃']
                        }
                    ]
                },
                {
                    key: 'IMU相关',
                    tableData: [
                        {
                            title: '量程',
                            contentList: [
                                '陀螺仪：±250（125/250/500/1000）°/S',
                                '加速度计：±4（2/4/8/16）g'
                            ]
                        },
                        {
                            title: '零偏不稳定性',
                            contentList: [
                                '陀螺仪：≤3°/h',
                                '加速度计：≤0.05mg'
                            ]
                        },
                        {
                            title: '角度随机游走',
                            contentList: [
                                '陀螺仪：≤0.21°/√h',
                                '加速度计：/'
                            ]
                        },
                        {
                            title: '速度随机游走',
                            contentList: [
                                '陀螺仪：/',
                                '加速度计：≤0.04m/s/√hr'
                            ]
                        }
                    ]
                }
            ]" />


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c" style="justify-content: center;">
            <router-link to="/about/contact" style="text-align: center; width: 100%;">
                <AdapButtomBlack text="获取详细产品信息" />
            </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            font-weight: bold;
            text-align: center;">
            应用场景
        </h3>


    </div>
    <div class="div-spacer-h80"></div>


    <div class="h-c">

        <div v-show="!isMobile" class="v-c">
            <ScrollingCards2 :items="ScrollingCards2_items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards2 :items="ScrollingCards2_items" />
        </div>





    </div>


    <div class="div-spacer-h288"></div>





</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner车规产品/imu合集.2.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}




.dfsghtr5 {
    padding: 0px 20px;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
}
.dfsghtr5 a{
    width: fit-content;
}
</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards2 from '@/components/ScrollingCards2.vue';
import mScrollingCards2 from '@/components/mScrollingCards2.vue';
const ScrollingCards2_items = [
    { image: require('@/assets/images/官网开发素材/inertia/p2.jpg'), title: '隧道内岔路口', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p3.jpg'), title: '高架桥下', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p4.jpg'), title: '地下停车场', desc: '无卫星信号' },
    { image: require('@/assets/images/官网开发素材/inertia/p6.jpg'), title: '城市峡谷', desc: '卫星信号弱，多路劲效应影响' },
    { image: require('@/assets/images/官网开发素材/inertia/p5.jpg'), title: '复杂立体交通', desc: '卫星信号无/弱' }
];
import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import FeatureCard from '@/components/FeatureCard.vue';
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtom from '@/components/AdapButtom.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>