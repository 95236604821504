<template>
    <div class="main">



        <BannerSwiper class="banner-swiper" />

        <div class="div-spacer-h288"></div>

        <div class="h-c" style="text-align: center;">
            <h3 style="color: rgb(0, 0, 0);
              
              
              
           
              
              font-weight: bold;
              position: relative;
              
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              text-wrap: nowrap" class="text-size-96 that-line">
                赤诚之芯<br />智联未来</h3>
        </div>
        <div class="div-spacer-h288"></div>

        <div class="h-c">
            <div class="v-c rev">

                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-items: left;gap: var(--gap-16);">

                    <div style="color: rgb(0, 0, 0);
                  
                  
                  font-weight: bold;
                  
                  
                  " class="text-size-56 titleline">
                        车规级-卫惯产品
                    </div>
                    <div style="color: rgba(89, 89, 89,1);
                  
                  
                  font-weight: bold;
                  max-width: 475px;
                  
                  text-align: left;" class="text-size-16">
                        提供卫星拒止下的厘米级定位和导航。
                        车规级技术，超高性能，多元融合算法，在各种复杂环境下提供超稳定、
                        高精度定位服务，满足L2+以上智能驾驶需求。
                    </div>
                    <div>
                        <a v-show="!isMobile" href="/product/automotive">
                            <AdapButtomBold text='了解详情' />
                        </a>

                    </div>

                </div>

                <PictureCard style="max-width: 621px;height: 351px;"
                    :src="require('@/assets/images/官网开发素材/首页/隧道2b.jpg')" />




            </div>

        </div>
        <div class="div-spacer-h80"></div>
        <div class="h-c">
            <div class="v-c" style="gap: 16px;">
                <div class="g-c-4-2-1 disktop-card">
                    <newSummaryCard number="1" subtitle="优势一" title="自研算法" strength="强"
                        description="自研融合算法体系，拥有六十余年军工融合，成熟可靠的技术成果积累沉淀。" />
                    <newSummaryCard number="2" subtitle="优势二" title="性价比" strength="高"
                        description="拥有完善完整的供应链体系和合规量产体系，将打造极具高附加值，高性价比的产品。" />
                    <newSummaryCard number="3" subtitle="优势三" title="产品形态" strength="多"
                        description="运用多年电子产品堆叠技术积累，创新打造多传感、多算法、多模组融合，以此满足车厂降本增效的业务需求。" />
                    <newSummaryCard number="4" subtitle="优势四" title="性能指标" strength="优"
                        description="全场景应用中达到行业顶尖水平，在各种复杂环境提供高精度的产品组合和数据输出。" />

                </div>


            </div>
            <div v-show="isMobile" class="v-c">
                <div class="div-spacer-h16"></div>
                <a href="/product/automotive">
                    <AdapButtomBold text='了解详情' />
                </a>
            </div>
        </div>

        <div class="div-spacer-h288"></div>


        <div class="h-c">
            <div class="v-c rev">

                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-items: left;gap: var(--gap-16);width: fit-content;">

                    <div class="text-size-56 titleline" style="color: rgb(0, 0, 0);
                  
                  
                  font-weight: bold;">
                        行业级-卫惯产品
                    </div>
                    <div class="text-size-16" style="color: rgba(89, 89, 89,1);
                  
                  max-width: 475px;
                  font-weight: bold;
                  text-align: left;">
                        提供卫星拒止下的厘米级定位和导航。超高性能，多元融合算法。如精准之匙开启各行各业效率与精度之门，引领未来新方向。
                    </div>
                    <div>
                        <a v-show="!isMobile" href="/product/industry">
                            <AdapButtomBold text='了解详情' />
                        </a>
                    </div>

                </div>


                <div style="width: fit-content;display: flex;justify-content: flex-end;flex-direction: column;">

                    <img :src="require('@/assets/images/官网开发素材/首页/行业4拼图.jpg')"
                        style="max-width: fit-content; height: 351px;" />

                </div>



            </div>

        </div>
        <div class="div-spacer-h80"></div>
        <div class="h-c">
            <div class="v-c" style="gap: 16px;">

                <div class="g-c-4-2-1 disktop-card">
                    <newSummaryCard number="1" subtitle="优势一" title="自研算法" strength="强"
                        description="以IMU为核心，融合GNSS/RTK，里程计，激光雷达，视频数据等传感器数据，提供自由组合的融合算法。" />
                    <newSummaryCard number="2" subtitle="优势二" title="性价比" strength="高"
                        description="拥有完善完整的供应链体系和合规量产体系，将打造极具高附加值，高性价比的产品。" />
                    <newSummaryCard number="3" subtitle="优势三" title="产品形态" strength="多"
                        description="运用多年电子产品堆叠技术积累，创新打造多传感、多算法、多模组融合，以此满足车厂降本增效的业务需求。" />
                    <newSummaryCard number="4" subtitle="优势四" title="性能指标" strength="优"
                        description="全场景应用中达到行业顶尖水平，在各种复杂环境提供高精度的产品组合和数据输出。" />

                </div>



            </div>
            <div v-show="isMobile" class="v-c">
                <div class="div-spacer-h16"></div>
                <a href="/product/industry">
                    <AdapButtomBold text='了解详情' />
                </a>
            </div>
        </div>
        <div class="div-spacer-h288"></div>

        <div class="h-c">
            <div class="v-c rev">

                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-items: left;gap: var(--gap-16);">

                    <div class="text-size-56 titleline" style="color: rgb(0, 0, 0);
                  
                  
                  font-weight: bold;">
                        应用场景解决方案
                    </div>
                    <div class="text-size-16" style="color: rgba(89, 89, 89,1);
                  
                  
                  
                  font-weight: bold;

                  max-width: 475px;
                  text-align: left;">
                        行业场景高精定位定制化解决方案融合了卫星定位技术、惯性定位技术、视觉感知技术、激光雷达技术。为各行业定位需求提供稳定、高效、高精度的信息服务。
                    </div>
                    <div>
                        <a v-show="!isMobile" href="/solution">
                            <AdapButtomBold text='了解详情' />
                        </a>
                    </div>

                </div>



                <PictureCard style="max-width: 621px;height: 351px;"
                    :src="require('@/assets/images/官网开发素材/行业解决方案/港口/港口.jpg')" />




            </div>

        </div>
        <div class="div-spacer-h80"></div>

        <div class="h-c">
            <div class="v-c" style="gap: 16px;">

                <div class="g-c-4-2-2 disktop-card">
                    <newSummaryCard3 number="1" title="智能驾驶汽车"
                        description="智能驾驶汽车行业是当今科技领域最具活力和发展潜力的行业之一，且需要厘米级的全域持续定位。" />
                    <newSummaryCard3 number="2" title="智慧测绘仪器"
                        description="测绘行业对数据处理的速度和实时性要求较高。然而，随着测绘数据量的不断增加，数据处理成为了一个瓶颈。" />

                    <newSummaryCard3 number="3" title="智能机器人"
                        description="四足、人形、轮式等机器人形态智能化水平越来越高，对于定位、姿态、感知精度的要求也越来越高。" />
                    <newSummaryCard3 number="4" title="自动化农机"
                        description="农机自动化是在行业内日渐普及，农机通过高精度定位服务实现高效率、高精度、高质量的农业作业。" />
                    <newSummaryCard3 number="5" title="低速无人车"
                        description="无人车行业是智能汽车发展的高级形态，无人驾驶汽车已逐步从研发阶段走向商业化应用，同时需要厘米级的全域持续定位服务。" />
                    <newSummaryCard3 number="6" title="自动化工程机械"
                        description="工程机械自动化需要对自身的定位、姿态以及对外界复杂环境信息有高精度的感知，高精度定位服务起到了不可替代的作用。" />
                    <newSummaryCard3 number="7" title="无人飞行器 "
                        description="无人飞行器通过高精度定位服务解决精度稳定性不足， 信号干扰遮蔽，姿态控制精度等技术难题。" />
                    <newSummaryCard3 number="8" title="港口船舶自动化 "
                        description="港口船舶、人员、车辆定位定向是智慧港口系统中的重要组成部分，精准的定位定向，帮助实现精准的港口船舶、人员、车辆管理。" />
                </div>
            </div>
            <div v-show="isMobile" class="v-c">
                <div class="div-spacer-h16"></div>
                <a href="/solution">
                    <AdapButtomBold text='了解详情' />
                </a>
            </div>

        </div>



        <div class="div-spacer-h288"></div>













        <div class="h-c">
            <div class="v-c rev">

                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-items: left;gap: var(--gap-16);">

                    <div class="text-size-56 titleline" style="color: rgb(0, 0, 0);
                  
                  
                  font-weight: bold;">
                        量产交付
                    </div>
                    <div class="text-size-16" style="color: rgba(89, 89, 89,1);
                  
                  
                  font-weight: bold;
                  
                  max-width: 475px;
                  text-align: left;">
                        依托高精密智能实验中心、智能仓储管理平台、车规级全流程自动化生产、数字信息化管理平台，为客户提供车规级，优质，可靠的产品。
                    </div>
                    <div>
                        <a v-show="!isMobile" href="/deliver">
                            <AdapButtomBold text='了解详情' />
                        </a>
                    </div>

                </div>



                <PictureCard style="max-width: 621px;height: 351px;"
                    :src="require('@/assets/images/官网开发素材/banner/量产交付.jpg')" />




            </div>

        </div>
        <div class="div-spacer-h80"></div>
        <div class="h-c">
            <div class="v-c">

                <div class="g-c-3-1-1 disktop-card" style="gap: 16px;">
                    <newSummaryCard2 number="1" title="车规级全流程自动化生产线"
                        description="车规级高精度定位整机及模块具备全自动装配及测试一体化产线，可通过数字信息化管理系统保证产品的品质和产品交付能力。" />

                    <newSummaryCard2 number="2" title="智能化制造及管理基地 "
                        description="借助智能仓储管理、自动化产品装配、检测一体化流程，数字信息化管理，实现低成本、高效能的生产模式。" />

                    <newSummaryCard2 number="3" title="实验测试中心 "
                        description="智能导航实验室，为产品大规模量产、产品试产阶段的各类验证和分析实验提供了全方面的技术支撑。" />

                </div>





            </div>
            <div class="v-c">
                <div class="div-spacer-h16"></div>
                <a v-show="isMobile" href="/deliver">
                    <AdapButtomBold text='了解详情' />
                </a>
            </div>
        </div>
        <div class="div-spacer-h288"></div>










    </div>
</template>



<style scoped>
div {
    width: 100%;
}

.titleline {
    text-align: left;
}


a {
    width: fit-content;
}


.that-line:before {
    content: '';
    display: block;
    width: 90px;
    border-bottom: solid 4px #000;
    margin-right: 50px;
}

.that-line:after {
    content: '';
    display: block;
    width: 90px;
    border-bottom: solid 4px #000;
    margin-left: 50px;
}


.disktop-card {

    padding-top: 8px;
    padding-bottom: 8px;

}


@media (max-width: 768px) {
    .disktop-card {

        padding-top: 0px;
        padding-bottom: 0px;

    }

    .titleline {
        text-align: center;
    }

    .PictureCard {
        min-width: 100% !important;
    }

    /* 圆角针对性调整 */
    img {
        object-fit: contain;
        /* height: fit-content!important; */
        height: min-content !important;
    }


    /* 那两条线 */
    .that-line:before {
        content: '';
        display: block;
        width: 45px;
        border-bottom: solid 1px #000;
        margin-right: 25px;
    }

    .that-line:after {
        content: '';
        display: block;
        width: 45px;
        border-bottom: solid 1px #000;
        margin-left: 25px;
    }




}
</style>




<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import newSummaryCard3 from '@/components/newSummaryCard3.vue';
import newSummaryCard2 from '@/components/newSummaryCard2.vue';
import newSummaryCard from '@/components/newSummaryCard.vue';
import BannerSwiper from '@/components/BannerSwiper.vue';
import AdapButtomBold from '@/components/AdapButtomBold.vue';
import PictureCard from '@/components/PictureCard.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()

</script>
