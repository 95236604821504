<!-- 解决方案-机器人 -->


<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255);
                
                
                font-weight: bold;
                
                
                text-align: left;">

                    行业解决方案

                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(255, 255, 255);
                
                
                
                
                
                text-align: left;">

                    行业场景高精定位定制化解决方案融合了卫星定位技术、惯性定位技术、视觉感知技术、激光雷达技术。为各行业定位需求提供稳定、高效、高精度的信息服务。

                </p>


            </div>





        </div>

    </div>
<div class="div-spacer-h288"></div>

    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/智能机器人/机器人狗.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 275px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    智能机器人
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    智能机器人是一种集成了感知、决策、执行和学习能力的先进机械设备。它们不仅能够执行单一的任务，还能够根据环境和外部输入的变化，做出相应的反应和决策。
                    智能机器人在工业制造、服务领域、社交互动、教育领域等多种领域中发挥着重要的作用。
                    随着技术创新的发展、产业链的完善，应用场景不断拓展，商业模式不断创新，政策的引导和推进，智能机器人行业将会迎来更为广阔的发展前景。
                </p>
            </div>

        </div>
    </div>

    <div class="div-spacer-h120"></div>
    <div class="div-spacer-h120"></div>
    <div class="h-c" >
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: center;">

                    智能机器人-行业痛点

                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">

                    四足、人形、轮式等机器人形态智能化水平越来越高，对于定位、姿态、感知精度的要求也越来越高。从定位角度来说，机器人在执行精确任务时，如救援、探测等，需要高精度的定位信息。然而，由于环境变化和传感器误差的影响，实现高精度定位是一个挑战。

                    从姿态控制角度来说，为适应各种任务场景，机器人的形态和结构复杂多样，如何在行走、跑步甚至跳跃等动态过程中，保持稳定的平衡状态也极具挑战。

                    从感知层面来说，为了提高环境感知的准确性和鲁棒性，需要采用多传感器融合技术。
                    从定位、姿态到感知，IMU / INS惯性组合定位系统，都能起到重要作用，以提高其精度和系统鲁棒性。


                </p>

            </div>

        </div>

    </div>

    <div class="div-spacer-h120"></div>
    

    <div class="h-c">

        <img :src="require('@/assets/images/官网开发素材/行业解决方案/智能机器人/信息分布图(1).svg')" alt="Photo" class="photo" style="max-width: 1094.457px;max-height: 773.864px;"/>

        

    </div>

    

    <div class="div-spacer-h120"></div>
    <div class="div-spacer-h120"></div>


    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/智能机器人/机器人2.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    产品优势
                </h3>






                












                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    
                    
                    text-align: left;
                    list-style-position: inside;">
                    <li>国内多家知名企业四足/人形机器人产品适配验证通过</li>
                    <li>车规级器件</li>
                    <li>高性价比</li>
                    <li>高可靠性</li>
                    <li>算法带来高精度输出</li>
                    <li>全面的技术支持服务</li>
                </ul>
            </div>

        </div>
    </div>
    <div class="div-spacer-h120"></div>
    <div class="div-spacer-h120"></div>


    <div class="h-c" >
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            
            
            
            
            
            text-align: center;">


            相关产品

        </h3>

    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c">


        <RelatedScrollingCards3 :products="RelatedScrollingCards3Products" :visibleImages="2" />

    </div>
    <div class="div-spacer-h288"></div>









</template>



<style scoped>
.banner {

    
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("@/assets/images/官网开发素材/banner/行业解决方案banner/机器人.jpg");
    z-index: -1;
    
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果

import PictureCard from '@/components/PictureCard.vue';
import RelatedScrollingCards3 from '@/components/RelatedScrollingCards3.vue';
const RelatedScrollingCards3Products = [
  {
    imageUrl: require('@/assets/images/官网开发素材/工规产品/cxgy-10 IMU/CXGY-10 IMU.jpg'),
    title: "CXGY-10 IMU",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy10"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-20 VRU/CXGY-20 VRU2.jpg"),
    title: "CXGY-20 VRU",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy20"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-30 AHRS/CXGY-30 AHRS.17.jpg"),
    title: "CXGY-30 AHRS",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy30"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-50 INS-BOX/CXGY-50 INS-BOX双天线pbox.5.jpg"),
    title: "CXGY-50 INS-BOX",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy50"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/cxgy-110 IMU/CXGY-110 IMU.1.jpg"),
    title: "CXGY-110 IMU",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy110"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-150 INS-BOX双天线/CXGY-150 INS-BOX双天线pbox.3.jpg"),
    title: "CXGY-150 INS-BOX",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy150"
  }
]




</script>