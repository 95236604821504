import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入全局样式 解决 1px 问题
import './assets/global.css'

createApp(App).use(store).use(router).mount('#app')

