<template>



    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">
                <p class="text-size-64" style="color: rgb(0, 0, 0);
                    
                    

                    
                    
                    text-align: left;">
                    IMU-BOX整机
                </p>

                <h1 class="text-size-96" style="color: rgb(0, 0, 0);
                    
                    font-weight: bold;
                    text-wrap: nowrap;
                    text-align: left;">
                    IB11
                </h1>


                <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                    IB11集成三轴陀螺和三轴加速度计的车规级通用性惯性测量单元，支持12V/24供电和CAN网络数据输出，产品经过全温范围标定，采用铝合金外壳，为苛刻的应用场景提供可靠的惯性测量性能。
                </p>


            </div>






        </div>

    </div>

    <div v-show="isMobile" class="h-c">
      <div class="div-spacer-h288"></div>
        <div class="v-c">

            

            <p class="text-size-16" style="color: rgba(89, 89, 89,1);text-align: left;">
                IB11集成三轴陀螺和三轴加速度计的车规级通用性惯性测量单元，支持12V/24供电和CAN网络数据输出，产品经过全温范围标定，采用铝合金外壳，为苛刻的应用场景提供可靠的惯性测量性能。
            </p>

            
        </div>
        <div class="div-spacer-h288"></div>
    </div>
    <div v-show="!isMobile" class="div-spacer-h288"></div>





    <div class="h-c">

        <CombinedTitle title="IMU-BOX" shortTitle="" subTitle="IMU BOX" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/整机/IB11/IB11-1.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    产品构成
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">

                    <li>三轴加速度计 （加计）</li>
                    <li>三轴角速度计 （陀螺）</li>
                    <li>阵列IMU （2/4/8）</li>
                </ul>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    font-weight: bold;
                    
                    text-align: left;">
                    本导航系统动态性能优势明显，能够有效地应对卫星信号干扰、丢失等苛刻环境，为载体提供连续、稳定和可信的高精度位置及姿态信息。
                </p>
            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/整机/IB11/IB11-2.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    产品特点
                </h3>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">
                    IMU系列模组全温标定，易于前期测试集成度高，性能稳定采用不锈钢外壳，为苛刻的应用场景提供可靠的惯性测量性能。
                </p>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    




                    
                    text-align: left;">

                    <li>全温标定</li>
                    <li>易于前期测试集成度高</li>
                    <li>性能稳定</li>
                </ul>



            </div>


        </div>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <p class="text-size-64" style="text-align: center;
            color: rgb(0, 0, 0);
            
            
            

            font-weight: bold;
            text-align: center;">
            产品性能参数
        </p>

    </div>

    <div class="div-spacer-h80"></div>




    <div class="h-c">
        <div class="v-c">

            <SpecTable :specData="[
    {
      key: '物理和电气',
      tableData: [
        {
          title: '尺寸',
          contentList: ['108*67*30mm(不含连接器)']
        },
        {
          title: '重量',
          contentList: ['188±10g']
        },
        {
          title: '输入电压',
          contentList: ['9.0V～16.0V']
        },
        {
          title: '功耗',
          contentList: ['1.2W']
        },
        {
          title: '主连接器接口',
          contentList: ['JAE MX23A12NF1']
        }
      ]
    },
    {
      key: '环境及其他',
      tableData: [
        {
          title: '工作温度',
          contentList: ['-40℃～105℃']
        },
        {
          title: '储存温度',
          contentList: ['-55℃～125℃']
        },
        {
          title: '湿度',
          contentList: ['95%非凝露']
        },
        {
          title: '防尘防水等级',
          contentList: ['IP67']
        },
        {
          title: '振动',
          contentList: ['ISO 16750-3-2023']
        }
      ]
    },
    {
      key: 'IMU相关',
      tableData: [
        {
          title: '量程',
          contentList: [
            '陀螺仪：±250（125/250/500/1000）°/S',
            '加速度计：±4（2/4/8/16）g'
          ]
        },
        {
          title: '零偏不稳定性',
          contentList: [
            '陀螺仪：≤3°/h',
            '加速度计：≤0.05mg'
          ]
        },
        {
          title: '角度随机游走',
          contentList: [
            '陀螺仪：≤0.21°/√h',
            '加速度计：/'
          ]
        },
        {
          title: '速度随机游走',
          contentList: [
            '陀螺仪：/',
            '加速度计：≤0.04m/s/√hr'
          ]
        }
      ]
    }
  ]" />

        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c" style="justify-content: center;">
            <router-link to="/about/contact" style="text-align: center; width: 100%;">
        <AdapButtomBlack text="获取详细产品信息" />
      </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            font-weight: bold;
            text-align: center;">
            应用场景
        </h3>


    </div>
    <div class="div-spacer-h80"></div>


    <div class="h-c">

        <div v-show="!isMobile" class="v-c">
            <ScrollingCards2 :items="ScrollingCards2_items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards2 :items="ScrollingCards2_items" />
        </div>





    </div>


    <div class="div-spacer-h288"></div>





</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner车规产品/科技正圆+imubox.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards2 from '@/components/ScrollingCards2.vue';
import mScrollingCards2 from '@/components/mScrollingCards2.vue';
const ScrollingCards2_items = [
    { image: require('@/assets/images/官网开发素材/inertia/p2.jpg'), title: '隧道内岔路口', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p3.jpg'), title: '高架桥下', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p4.jpg'), title: '地下停车场', desc: '无卫星信号' },
    { image: require('@/assets/images/官网开发素材/inertia/p6.jpg'), title: '城市峡谷', desc: '卫星信号弱，多路劲效应影响' },
    { image: require('@/assets/images/官网开发素材/inertia/p5.jpg'), title: '复杂立体交通', desc: '卫星信号无/弱' }
];
import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import FeatureCard from '@/components/FeatureCard.vue';
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtom from '@/components/AdapButtom.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>