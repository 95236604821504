<!-- 解决方案-工程机械 -->

<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255);
                
                
                font-weight: bold;;
                
                
                text-align: left;">

                    行业解决方案

                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(255, 255, 255);
                
                
                
                
                
                text-align: left;">

                    行业场景高精定位定制化解决方案融合了卫星定位技术、惯性定位技术、视觉感知技术、激光雷达技术。为各行业定位需求提供稳定、高效、高精度的信息服务。

                </p>


            </div>





        </div>

    </div>
<div class="div-spacer-h288"></div>

    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/工程机械自动化/矿区1.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 275px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    工程机械自动化
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    工程机械自动化是利用计算机技术、控制技术、传感器技术，
                    对工程机械进行自动化操作和控制技术，
                    自动化可以有效提高工程机械的作业精度、作业安全性和作业效率。
                </p>
            </div>

        </div>
    </div>
    <div class="div-spacer-h288"></div>


    <div class="h-c" >
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: center;">

                    工程机械自动化-行业痛点

                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">

                    工程机械自动化一方面需要对自身的定位、姿态以及对外界环境信息有高精度的感知，需要多种高精度传感器融合协同，提升感知精度。

                    另一方面，工程机械工作环境复杂多变，复杂环境下的环境感知对系统鲁棒性以及安全性提出了较高的要求
                    MEMS惯性传感器凭借其高精度、高频率、高稳定性、不依赖外界环境信息输入的特性，在工程机械姿态控制、复杂环境下的高精度定位、复杂场景下的运动控制，起到了不可替代的作用。


                </p>

            </div>

        </div>

    </div>





    <div class="div-spacer-h288"></div>

    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/工程机械自动化/工程车2.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    产品优势
                </h3>



                








                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    
                    
                    text-align: left;
                    list-style-position: inside;">
                    <li>车规级器件</li>
                    <li>高性价比</li>
                    <li>高可靠性、适用于各种极端、复杂场景</li>
                    <li>算法带来高精度输出</li>
                    <li>全面的技术支持服务</li>
                    
                </ul>
            </div>

        </div>
    </div>

    <div class="div-spacer-h288"></div>


    <div class="h-c" >
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            
            
            
            
            
            text-align: center;">


            相关产品

        </h3>

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c" >


        <RelatedScrollingCards3 :products="RelatedScrollingCards3Products" :visibleImages="2" />

    </div>







    <div class="div-spacer-h288"></div>



</template>



<style scoped>
.banner {

    
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("@/assets/images/官网开发素材/banner/行业解决方案banner/矿区2.jpg");
    z-index: -1;
    
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果

import PictureCard from '@/components/PictureCard.vue';
import RelatedScrollingCards3 from '@/components/RelatedScrollingCards3.vue';
const RelatedScrollingCards3Products = [
  {
    imageUrl: require('@/assets/images/官网开发素材/工规产品/cxgy-10 IMU/CXGY-10 IMU.jpg'),
    title: "CXGY-10 IMU",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy10"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-20 VRU/CXGY-20 VRU2.jpg"),
    title: "CXGY-20 VRU",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy20"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-30 AHRS/CXGY-30 AHRS.17.jpg"),
    title: "CXGY-30 AHRS",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy30"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-50 INS-BOX/CXGY-50 INS-BOX双天线pbox.5.jpg"),
    title: "CXGY-50 INS-BOX",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy50"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/cxgy-110 IMU/CXGY-110 IMU.1.jpg"),
    title: "CXGY-110 IMU",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy110"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-150 INS-BOX双天线/CXGY-150 INS-BOX双天线pbox.3.jpg"),
    title: "CXGY-150 INS-BOX",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy150"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGNM121SA/单北斗GNSS.5.jpg"),
    title: "CXGY-B10",
    description: "北斗定位模组",
    link: "/product/industry/cxgyb10"
  }
]




</script>