<!-- 量产交付 -->

<template>


    <div class="h-c banner" style="justify-content: center;">

        <div
            style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
            <h1 class="text-size-72" style="color: rgb(255, 255, 255);
                
                
                
                
                
                
                text-align: center;">

                量产交付

            </h1>

            <div class="div-spacer-h16"></div>
            <p class="text-size-24" style="color: rgb(255, 255, 255);
                
                
                

                padding: 0px 28px;
                text-align: center;
                ">

                依托高精密智能实验中心、智能仓储管理平台、车规级全流程自动化生产、数字信息化管理平台，为客户提供车规级，优质，可靠的产品。

            </p>


        </div>


    </div>


    <div class="div-spacer-h288"></div>


    <div v-show="!isMobile" class="h-c">
        <div class="v-c">


            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 275px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    车规级全流程自动化生产线
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    

                    color: rgb(89, 89, 89);
                    text-align: left;">
                    车规级高精度定位整机及模块具备全自动装配及测试一体化产线，可通过数字信息化管理系统可精确追溯单件产品全生产周期，从而保证产品的品质和产品交付能力。
                </p>
            </div>

            <PictureCardRolling
                :images="[require('@/assets/images/官网开发素材/量产/车规级全流程自动化生产线1.jpg'), require('@/assets/images/官网开发素材/量产/车规级全流程自动化生产线2.jpg'), require('@/assets/images/官网开发素材/量产/车规级全流程自动化生产线3.jpg')]"
                style="max-width: 621px;height: 351px;" />

        </div>
    </div>

    <div v-show="isMobile" class="h-c">
        <div class="v-c">
            <PictureCardRolling
                :images="[require('@/assets/images/官网开发素材/量产/车规级全流程自动化生产线1.jpg'), require('@/assets/images/官网开发素材/量产/车规级全流程自动化生产线2.jpg'), require('@/assets/images/官网开发素材/量产/车规级全流程自动化生产线3.jpg')]"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 275px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    车规级全流程自动化生产线
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    color: rgb(89, 89, 89);

                    
                    text-align: left;">
                    车规级高精度定位整机及模块具备全自动装配及测试一体化产线，可通过数字信息化管理系统可精确追溯单件产品全生产周期，从而保证产品的品质和产品交付能力。
                </p>
            </div>

        </div>
    </div>



    <div class="div-spacer-h288"></div>




    <div class="h-c">
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/量产/智能化制造及管理基地.jpg')"
                style="max-width: 621px;height: 351px;" />



            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 480px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                text-align: left;">
                    智能化制造及管理基地
                </h3>














                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    color: rgb(89, 89, 89);
                    text-align: left;">
                    借助智能仓储管理、自动化产品装配、检测一体化流程，数字信息化管理，实现低成本、高效能的生产模式。
                </p>
            </div>

        </div>
    </div>
    <div class="div-spacer-h288"></div>

    <div v-show="!isMobile" class="h-c">
        <div class="v-c">


            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    实验测试中心
                </h3>









                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    color: rgb(89, 89, 89);
                    
                    text-align: left;">
                    智能导航实验室，为产品大规模量产、产品试产阶段的各类验证和分析实验提供了全方面的技术支撑。
                </p>
            </div>

            
            <PictureCardRolling
                :images="[require('@/assets/images/官网开发素材/量产/诚芯实验室1.jpg'), require('@/assets/images/官网开发素材/量产/诚芯实验室2.jpg'), require('@/assets/images/官网开发素材/量产/诚芯实验室3.jpg')]"
                style="max-width: 621px;height: 351px;" />

        </div>
    </div>

    <div v-show="isMobile" class="h-c">
        <div class="v-c">

            
            <PictureCardRolling
                :images="[require('@/assets/images/官网开发素材/量产/诚芯实验室1.jpg'), require('@/assets/images/官网开发素材/量产/诚芯实验室2.jpg'), require('@/assets/images/官网开发素材/量产/诚芯实验室3.jpg')]"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    实验测试中心
                </h3>









                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    color: rgb(89, 89, 89);

                    
                    text-align: left;">
                    智能导航实验室，为产品大规模量产、产品试产阶段的各类验证和分析实验提供了全方面的技术支撑。
                </p>
            </div>


        </div>
    </div>

    <div class="div-spacer-h288"></div>




</template>



<style scoped>
.banner {


    
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("@/assets/images/官网开发素材/banner/量产交付.jpg");
    z-index: -1;

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}
</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import PictureCardRolling from '@/components/PictureCardRolling.vue';
import PictureCard from '@/components/PictureCard.vue';




</script>