<!-- 解决方案-测绘 -->

<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255);
                
                
                font-weight: bold;;
                
                
                text-align: left;">

                    行业解决方案

                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(255, 255, 255);
                
                
                
                
                
                text-align: left;">

                    行业场景高精定位定制化解决方案融合了卫星定位技术、惯性定位技术、视觉感知技术、激光雷达技术。为各行业定位需求提供稳定、高效、高精度的信息服务。

                </p>


            </div>





        </div>

    </div>
<div class="div-spacer-h288"></div>

    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/测绘/测绘3.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 275px;">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                font-weight: bold;;
                
                
                text-align: left;">
                    智慧测绘仪器
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    测绘行业是一个集技术、科学与管理于一体的综合性行业，它通过运用各种测量技术和方法，为地理信息系统(GIS)、国土资源管理、城市规划、交通等领域提供定位、测绘和绘图服务。为各类基础设施建设项目提供精确的地理信息数据，如道路、桥梁、铁路、水利工程等。准确的测绘成果确保工程设计的合理性和施工的准确性，降低工程风险和成本。
                </p>
            </div>

        </div>
    </div>
    <div class="div-spacer-h288"></div>


    <div class="h-c" >
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: center;">

                    智慧测绘仪器-行业痛点

                </h3>

                <p>
                    GPS遮挡或失锁问题<br>
                    在复杂环境如城市峡谷、森林覆盖区等，GPS信号容易受到遮挡或干扰，
                    导致定位精度下降甚至失锁，影响测绘作业的连续性和可靠性。
                </p>
                <p>
                    IMU漂移<br>
                    惯性测量单元（IMU）在长时间运行后会产生漂移，影响定姿精度。
                    虽然可以通过算法进行一定程度的校正，但完全消除漂移仍是一个技术难题。


                </p>
                <p>
                    多传感器融合技术不足<br>
                    测绘作业中通常需要融合多种传感器数据（如GPS、IMU、激光扫描仪、相机等）
                    以提高定位和定姿精度。然而，多传感器数据的融合处理需要复杂的算法和强大的计算能力，目前在这方面仍存在不足。


                </p>
                <p>
                    数据处理与实时性<br>
                    测绘行业对数据处理的速度和实时性要求较高。然而，随着测绘数据量的不断增加，
                    数据处理成为了一个瓶颈。如何在保证精度的同时提高数据处理速度和实时性，是当前面临的一个重要问题。

                </p>


            </div>

        </div>

    </div>


    <div class="div-spacer-h288"></div>




    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/测绘/测绘2.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    产品优势
                </h3>







                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    
                    
                    text-align: left;
                    list-style-position: inside;">
                    <li>精度高</li>
                    <li>融合度高、实时性好</li>
                    <li>成熟的姿态、航向、定位算法</li>
                    <li>高性价比</li>
                    <li>高可靠性</li>
                    <li>全面的技术支持服务</li>
                    

                </ul>
            </div>

        </div>
    </div>

    <div class="div-spacer-h288"></div>


    <div class="h-c" >
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            
            
            
            
            
            text-align: center;">


            相关产品

        </h3>

    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c">


        <RelatedScrollingCards3 :products="RelatedScrollingCards3Products" :visibleImages="2" />

    </div>
    <div class="div-spacer-h288"></div>









</template>



<style scoped>


.banner {

    
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("@/assets/images/官网开发素材/banner/行业解决方案banner/测绘1.jpg");
    z-index: -1;
    
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果

import PictureCard from '@/components/PictureCard.vue';
import RelatedScrollingCards3 from '@/components/RelatedScrollingCards3.vue';
const RelatedScrollingCards3Products = [
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/cxgy-110 IMU/CXGY-110 IMU.1.jpg"),
    title: "CXGY-110 IMU",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy110"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-150 INS-BOX双天线/CXGY-150 INS-BOX双天线pbox.3.jpg"),
    title: "CXGY-150 INS-BOX",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy150"
  }
]



</script>