<!-- 解决方案-农机 -->


<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255);
                
                
                font-weight: bold;;
                
                
                text-align: left;">

                    行业解决方案

                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(255, 255, 255);
                
                
                
                
                
                text-align: left;">

                    行业场景高精定位定制化解决方案融合了卫星定位技术、惯性定位技术、视觉感知技术、激光雷达技术。为各行业定位需求提供稳定、高效、高精度的信息服务。

                </p>


            </div>





        </div>

    </div>
<div class="div-spacer-h288"></div>

    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/自动化农机/智能割草机2.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 275px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    自动化农机
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    农机自动化行业处于快速发展阶段。随着科技的不断进步，越来越多的先进技术被应用到农业机械中。农机自动化是利用计算机技术、控制技术、传感器技术，对农业机械进行智能化设计，以实现农机在播种和收割、灌溉和施肥、病虫害防治等作业场景自主化、智能化、高效化的技术体系。
                </p>
            </div>

        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c" >
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: center;">

                    自动化农机-行业痛点

                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">

                    一方面农机作业环境地形复杂、作物多种多样，作业环境受天气的影响极大
                    另一方面，农机要实现精准的自动化作业，必须高精度的感知环境信息，精确的了解自身定位信息，并依据车身姿态作出精准控制。

                    IMU /
                    INS惯性传感器，惯性组合定位可以在农机运行时实时监测机器的倾斜、摇摆、航向等状态，并将这些信息传递给自动驾驶系统，让系统根据实时的路面情况和机器状况进行智能决策，调节农机的速度、轨迹和工作深度等参数，从而实现高效率、高精度、高质量的农业作业。

                </p>

            </div>

        </div>

    </div>




    <div class="div-spacer-h288"></div>


    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/自动化农机/农机车.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    产品优势
                </h3>







                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    
                    
                    text-align: left;
                    list-style-position: inside;">
                    <li>车规级器件</li>
                    <li>高性价比</li>
                    <li>高可靠性</li>
                    <li>算法带来高精度输出</li>
                    <li>全面的技术支持服务</li>

                </ul>
            </div>

        </div>
    </div>

    <div class="div-spacer-h288"></div>


    <div class="h-c" >
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            
            
            
            
            
            text-align: center;">


            相关产品

        </h3>

    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c">


        <RelatedScrollingCards3 :products="RelatedScrollingCards3Products" :visibleImages="2" />

    </div>
    <div class="div-spacer-h288"></div>









</template>



<style scoped>
.banner {

    
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("@/assets/images/官网开发素材/banner/行业解决方案banner/智慧农机.jpg");
    z-index: -1;
    
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果

import PictureCard from '@/components/PictureCard.vue';
import RelatedScrollingCards3 from '@/components/RelatedScrollingCards3.vue';
const RelatedScrollingCards3Products = [
  {
    imageUrl: require('@/assets/images/官网开发素材/工规产品/cxgy-10 IMU/CXGY-10 IMU.jpg'),
    title: "CXGY-10 IMU",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy10"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-20 VRU/CXGY-20 VRU2.jpg"),
    title: "CXGY-20 VRU",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy20"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-30 AHRS/CXGY-30 AHRS.17.jpg"),
    title: "CXGY-30 AHRS",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy30"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-50 INS-BOX/CXGY-50 INS-BOX双天线pbox.5.jpg"),
    title: "CXGY-50 INS-BOX",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy50"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/cxgy-110 IMU/CXGY-110 IMU.1.jpg"),
    title: "CXGY-110 IMU",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy110"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-150 INS-BOX双天线/CXGY-150 INS-BOX双天线pbox.3.jpg"),
    title: "CXGY-150 INS-BOX",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy150"
  }
]




</script>