<template>
    <!-- Add overlay -->
    <transition name="fade">
        <div v-show="isMenuOpen || (activeMenu && activeMenu.submenu)" class="full-screen-overlay" @click="handleOverlayClick"></div>
    </transition>
    <nav class="navbar">
        <div class="navbar-container">
            <div class="navbar-content">

                <!-- Logo -->
                <a href="/">
                    <div class="navbar-logo">
                        <img src="@/assets/images/svgs/logo.dark.svg" alt="Logo">
                    </div>
                </a>

                <!-- Desktop Navigation -->
                <div class="navbar-menu">
                    <template v-for="item in menuItems" :key="item.name">
                        <div class="navbar-item" @mouseenter="handleMenuEnter(item)" @mouseleave="handleMenuLeave">
                            <a :href="item.href" class="navbar-link">
                                {{ item.name }}
                            </a>
                            <!-- Dropdown Menu -->
                            <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                                <div v-if="activeMenu && activeMenu.name === item.name && item.submenu"
                                    class="dropdown-menu">

                                    <div class="dropdown-container" :style="{ left: `${dropdownPosition}px` }">
                                        <div class="dropdown-background" :style="{ left: `-${dropdownPosition}px` }">
                                        </div>
                                        <!-- Featured product -->
                                        <div class="featured-product" v-if="item.submenu.featured">
                                            <div v-for="product in item.submenu.featured" :key="product.name"
                                                class="featured-item" @mouseenter="handleSubMenuEnter(product)"
                                                @mouseleave="handleSubMenuLeave">
                                                <div class="featured-item-header">
                                                    <a v-if="!product.lvl2" :href="product.href">
                                                        <h3>{{ product.name }}</h3>
                                                    </a>
                                                    <div v-else class="featured-item-title" style="display: flex;flex-direction: row;width: 250px;align-items: flex-start;">
                                                        <h3>{{ product.name }}</h3>
                                                        <div v-if="activeSubMenu" style="display: flex;flex-direction: column;position: absolute;top: 24px;left: 120px;" >
                                                            <a :href="subb.href" v-for="subb in product.lvl2.featured">
                                                                <h3 style="margin-bottom: 36px;">{{subb.name}}</h3>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Third Level Menu -->
                                                <!-- <transition name="submenu">
                                                    <div v-if="product.lvl2" class="third-level-menu">
                                                        <div v-for="subItem in product.lvl2.featured"
                                                            :key="subItem.name" class="third-level-item">
                                                            <a :href="subItem.href">{{ subItem.name }}</a>
                                                        </div>
                                                    </div>
                                                </transition> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </template>
                </div>

                <!-- Right Icons -->
                <div class="navbar-icons">
                    <!-- Mobile Menu Button -->
                    <button @click="toggleMenu" class="menu-button">
                        <!-- <svg v-if="!isMenuOpen" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path
                                d="M19 2L19 15.5L22 15.5L18 20.5L14 15.5L17 15.5L17 2L19 2ZM11 18.5L11 20.5L2 20.5L2 18.5L11 18.5ZM13 10.5L13 12.5L2 12.5L2 10.5L13 10.5ZM13 2L13 4L2 4L2 2L13 2Z"
                                fill="#1F1F1F" fill-opacity="1.000000" fill-rule="evenodd" />
                        </svg> -->
                        <svg v-if="!isMenuOpen" width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>
                                Created with Pixso.
                            </desc>
                            <defs>
                                <clipPath id="clip2303_4032">
                                    <rect id="sort-desc" width="24.000000" height="24.000000" fill="white"
                                        fill-opacity="0" />
                                </clipPath>
                            </defs>
                            <rect id="折叠下拉icon" width="24.000000" height="24.000000" fill="#FFFFFF" fill-opacity="0" />
                            <rect id="sort-desc" width="24.000000" height="24.000000" fill="#FFFFFF" fill-opacity="0" />
                            <g clip-path="url(#clip2303_4032)">
                                <path id="矢量 71"
                                    d="M19 2L19 16.59L22 16.59L18 22L14 16.59L17 16.59L17 2L19 2ZM13 19.83L13 22L2 22L2 19.83L13 19.83ZM13 11L13 13L2 13L2 11L13 11ZM13 2L13 4.16L2 4.16L2 2L13 2Z"
                                    fill="#1F1F1F" fill-opacity="1.000000" fill-rule="evenodd" />
                            </g>
                        </svg>


                        <!-- <svg v-else fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path
                                d="M17 18.5L17 5L20 5L16 0L12 5L15 5L15 18.5L17 18.5ZM9 2L9 0L0 0L0 2L9 2ZM11 10L11 8L0 8L0 10L11 10ZM11 18.5L11 16.5L0 16.5L0 18.5L11 18.5Z"
                                fill="#1F1F1F" fill-opacity="1.000000" fill-rule="evenodd" />
                        </svg> -->
                        <svg v-else width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>
                                Created with Pixso.
                            </desc>
                            <defs>
                                <clipPath id="clip2303_4008">
                                    <rect id="sort-desc" width="24.000000" height="24.000000"
                                        transform="matrix(1 0 0 -1 0 24)" fill="white" fill-opacity="0" />
                                </clipPath>
                            </defs>
                            <rect id="折叠上折叠icon" width="24.000000" height="24.000000" transform="matrix(1 0 0 -1 0 24)"
                                fill="#FFFFFF" fill-opacity="0" />
                            <rect id="sort-desc" width="24.000000" height="24.000000" transform="matrix(1 0 0 -1 0 24)"
                                fill="#FFFFFF" fill-opacity="0" />
                            <g clip-path="url(#clip2303_4008)">
                                <path id="矢量 71"
                                    d="M19 22L19 7.4L22 7.4L18 2L14 7.4L17 7.4L17 22L19 22ZM13 4.16L13 2L2 2L2 4.16L13 4.16ZM13 13L13 11L2 11L2 13L13 13ZM13 22L13 19.83L2 19.83L2 22L13 22Z"
                                    fill="#1F1F1F" fill-opacity="1.000000" fill-rule="evenodd" />
                            </g>
                        </svg>

                    </button>
                </div>
            </div>
        </div>

        <!-- Mobile Menu -->
        <div v-show="isMenuOpen" class="mobile-menu">
            <template v-for="item in menuItems" :key="item.name">
                <div class="mobile-menu-item">
                    <!-- 主菜单项 -->
                    <div class="mobile-menu-header" @click="toggleMobileSubmenu(item)">
                        <a :href="item.mhref" class="mobile-link">{{ item.name }}</a>
                        <span v-if="item.submenu" class="mobile-menu-arrow"
                            :class="{ 'is-expanded': isSubmenuOpen(item) }">
                            <svg viewBox="0 0 12 12" width="12" height="12">
                                <path d="M2 4 L6 8 L10 4" fill="none" stroke="currentColor" stroke-width="1.5" />
                            </svg>
                        </span>
                    </div>

                    <!-- 子菜单 -->
                    <transition name="submenu">
                        <div v-if="item.submenu && isSubmenuOpen(item)" class="mobile-submenu">
                            <template v-if="item.submenu.featured">
                                <div v-for="subItem in item.submenu.featured" :key="subItem.name"
                                    class="mobile-submenu-item">
                                    <div class="mobile-submenu-header" @click="toggleMobileThirdLevel(subItem)"
                                        v-if="subItem.lvl2">
                                        <span class="mobile-sublink">{{ subItem.name }}</span>
                                        <span class="mobile-menu-arrow"
                                            :class="{ 'is-expanded': isThirdLevelOpen(subItem) }">
                                            <svg viewBox="0 0 12 12" width="12" height="12">
                                                <path d="M2 4 L6 8 L10 4" fill="none" stroke="currentColor"
                                                    stroke-width="1.5" />
                                            </svg>
                                        </span>
                                    </div>
                                    <a v-else :href="subItem.href" class="mobile-sublink">{{ subItem.name }}</a>

                                    <!-- Mobile Third Level Menu -->
                                    <transition name="submenu">
                                        <div v-if="subItem.lvl2 && isThirdLevelOpen(subItem)"
                                            class="mobile-third-level">
                                            <div v-for="thirdItem in subItem.lvl2.featured" :key="thirdItem.name"
                                                class="mobile-third-level-item">
                                                <a :href="thirdItem.href" class="mobile-third-link">
                                                    {{ thirdItem.name }}
                                                </a>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </template>
                        </div>
                    </transition>
                </div>
            </template>
        </div>

    </nav>
</template>

<script setup>
import { ref } from 'vue'

const menuItems = [
    {
        name: '首页',
        href: '/',
        mhref: '/'
    },
    {
        name: '产品服务',
        submenu: {
            featured: [
                {
                    name: '卫惯系列',
                    lvl2: {
                        featured: [
                            { name: '车规级-卫惯产品', href: '/product/automotive' },
                            { name: '行业级-卫惯产品', href: '/product/industry' }
                        ]
                    }
                },
                { name: '算法服务', href: '/service/algorithm' },
                { name: '智能驾舱生态', href: '/service/smart-cockpit' },
                { name: '软件服务', href: '/service/software' }
            ]
        }
    },
    {
        name: '解决方案',
        href: '/solution',
        submenu: {
            featured: [
                { name: '智能驾驶汽车', href: '/solution/smart-driving' },
                { name: '低速无人车', href: '/solution/low-speed-vehicle' },
                { name: '智能机器人', href: '/solution/smart-robot' },
                { name: '自动化工程机械', href: '/solution/engineering-machinery' },
                { name: '自动化农机', href: '/solution/agricultural-machinery' },
                { name: '无人飞行器', href: '/solution/uav' },
                { name: '港口船舶', href: '/solution/port-shipping' },
                { name: '智慧测绘仪器', href: '/solution/smart-surveying' }
            ]
        }
    },
    {
        name: '量产能力',
        href: '/deliver',
        mhref: '/deliver'
    },
    {
        name: '公司动态',
        href: '/news',
        mhref: '/news'
    },
    {
        name: '关于我们',
        submenu: {
            featured: [
                { name: '联系我们', href: '/about/contact' },
                { name: '企业概况', href: '/about' }
            ]
        }
    }
]

const isMenuOpen = ref(false)
const activeMenu = ref(null)
const activeSubMenu = ref(null)
const dropdownTimeout = ref(null)
const openMobileSubmenus = ref(new Set())
const openMobileThirdLevel = ref(new Set())
const dropdownPosition = ref(0)


// 添加点击遮罩层关闭的处理函数
const handleOverlayClick = (event) => {
  
    isMenuOpen.value = !isMenuOpen.value
};



const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value
}

const handleMenuEnter = (item) => {
    if (dropdownTimeout.value) {
        clearTimeout(dropdownTimeout.value)
    }
    activeMenu.value = item





    // 获取当前菜单项的DOM元素
    const menuItem = event.currentTarget
    // 获取元素的位置和尺寸信息
    const rect = menuItem.getBoundingClientRect()
    // console.log('菜单项位置信息：', {
    //     left: rect.left,           // 距离视口左边的距离
    //     right: rect.right,         // 距离视口右边的距离
    //     width: rect.width,         // 菜单项的宽度
    //     windowWidth: window.innerWidth  // 视口宽度
    // })

    // 先简单设置一下位置
    dropdownPosition.value = rect.left
    



}

const handleMenuLeave = () => {
    dropdownTimeout.value = setTimeout(() => {
        activeMenu.value = null
        activeSubMenu.value = null
    }, 200)
}

const handleSubMenuEnter = (product) => {
    if (product.lvl2) {
        activeSubMenu.value = product
        
    }
}

const handleSubMenuLeave = () => {
    activeSubMenu.value = null
}

const toggleMobileSubmenu = (item) => {
    if (openMobileSubmenus.value.has(item.name)) {
        openMobileSubmenus.value.delete(item.name)
    } else {
        openMobileSubmenus.value.add(item.name)
    }
}

const isSubmenuOpen = (item) => {
    return openMobileSubmenus.value.has(item.name)
}

const toggleMobileThirdLevel = (item) => {
    if (openMobileThirdLevel.value.has(item.name)) {
        openMobileThirdLevel.value.delete(item.name)
    } else {
        openMobileThirdLevel.value.add(item.name)
    }
}

const isThirdLevelOpen = (item) => {
    return openMobileThirdLevel.value.has(item.name)
}

const beforeEnter = (el) => {
    el.style.opacity = 0
    el.style.transform = 'translateY(-10px)'
}

const enter = (el, done) => {
    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            el.style.transition = 'all 0.25s ease-out'
            el.style.opacity = 1
            el.style.transform = 'translateY(0)'
            el.addEventListener('transitionend', done, { once: true })
        })
    })
}

const leave = (el, done) => {
    el.style.opacity = 0
    el.style.transform = 'translateY(-10px)'
    el.addEventListener('transitionend', done, { once: true })
}
</script>

<style scoped>
img {
    height: 18px;
    width: fit-content;
    object-fit: contain;
    max-width: 300px;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    z-index: 1000;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.02);
}

.navbar-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 22px;
}

.navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
}


.navbar-logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
}

.navbar-menu {
    display: none;
}

.navbar-link {
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    padding: 0 10px;
    opacity: 0.8;
    transition: opacity 0.3s;
}




.navbar-icons {
    display: flex;
    align-items: right;
    gap: 24px;
}

.menu-button {
    max-height: 38px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #000000;
    display: block;
    text-align: right;
}

.menu-button svg {
    height: 24px;
    width: 24px;
}

/* Desktop Styles */
@media (min-width: 768px) {
    .navbar-menu {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .menu-button {
        display: none;
    }

    .mobile-menu {
        display: none;
        z-index: 1000;
    }
}

.navbar-item {
    position: relative;
    height: 56px;
    display: flex;
    align-items: center;
    min-width: fit-content;
}

.navbar-item:hover{
    font-weight: bold;

}

.navbar-item:hover::after {

    content: "";
    position: absolute;
    
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;  /* 圆点的宽度 */
    height: 4px;  /* 圆点的高度 */
    background-color: currentColor;  /* 使用当前文字颜色 */
    border-radius: 50%;  /* 使其成为圆形 */
}


/* Dropdown Styles */
.dropdown-menu {
    position: fixed;
    left: 0;
    top: 56px;
    width: 100%;
    background: none;
    z-index: 1000;
}

.dropdown-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: -1;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.02);
}

.dropdown-container {
    max-width: fit-content;
    width: 100%;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    position: absolute;
}

/* Featured Item Styles */
.featured-product {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 20px;
    /* padding: 0 15px; */
}

.featured-item {
    text-align: left;
    padding: 8px 12px;
    transition: background-color 0.2s;
    border-radius: 6px;
    cursor: pointer;
}




.featured-item:hover {
    /* background-color: rgba(0, 0, 0, 0.05); */
    font-weight: bold !important;
}

.featured-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.featured-item-title {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: 100%;
}

.featured-item-title h3 {
    text-wrap: nowrap;


}


.featured-item h3 {
    color: #000000;
    font-size: 16px;
    margin-bottom: 4px;
    font-weight: 400;
}

/* Third Level Menu Styles */
.third-level-menu {
    margin-top: 8px;
    padding-left: 16px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.third-level-item {
    padding: 8px 0;
}

.third-level-item a {
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    opacity: 0.8;
    transition: opacity 0.2s;
}

.third-level-item a:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 2px;
}


/* Mobile Menu Styles */
.mobile-menu {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    max-height: calc(100vh - 56px);
    overflow-y: auto;
}

.mobile-menu-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 12px;
}

.mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 22px;
    cursor: pointer;
}

.mobile-link {
    display: block;
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    opacity: 0.8;
    transition: opacity 0.3s;
}



.mobile-menu-arrow {
    width: fit-content;
    color: #000000;
    transition: transform 0.3s ease;
}

.mobile-menu-arrow.is-expanded {
    transform: rotate(180deg);
}

.mobile-submenu {
    background-color: rgba(255, 255, 255, 0.5);
}

.mobile-submenu-item {
    padding: 12px 22px 12px 56px;
}

.mobile-submenu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.mobile-sublink {
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    opacity: 0.8;
}

/* Mobile Third Level Styles */
.mobile-third-level {
    background-color: rgba(255, 255, 255, 0.5);
    padding-left: 24px;
    padding-top: 18px;
}

.mobile-third-level-item {
    padding: 8px 0;
}

.mobile-third-link {
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    opacity: 0.8;
}

/* Animation Classes */
.dropdown-enter-active,
.dropdown-leave-active {
    transition: opacity 0.25s ease-out;
}

.dropdown-enter-from,
.dropdown-leave-to {
    opacity: 0;
}

.submenu-enter-active,
.submenu-leave-active {
    transition: all 0.3s ease;
    max-height: 1000px;
}

.submenu-enter-from,
.submenu-leave-to {
    opacity: 0;
    max-height: 0;
}


/* Add new styles for the overlay */
.full-screen-overlay {
    position: fixed;
    top: 56px;
    /* Same as navbar height */
    left: 0;
    width: 100%;
    height: calc(100vh - 56px);
    backdrop-filter: blur(50px);
    background: rgba(255, 255, 255, 0.3);
    /* -webkit-backdrop-filter: blur(8px); */
    z-index: 999;
    /* Between normal content and dropdown menu */
    /* pointer-events: none; */
    /* Allows clicking through to content underneath */
}

/* Add fade transition for overlay */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Media Queries */
@media (max-width: 767px) {
    .dropdown-container {
        flex-direction: column;
        padding: 20px 22px;
    }

    .featured-product {
        width: 100%;
        gap: 10px;
    }


}
</style>