<template>
    <div class="h-c banner" style="justify-content: center;">
        <!-- Banner content remains the same -->
        <div style="display: flex;flex-direction: column;justify-content: flex-end;">
            <h1 style="color: rgb(255, 255, 255);  text-align: center;" class="text-size-72">
                诚芯智联（武汉）科技技术有限公司
            </h1>
            <div class="div-spacer-h16"></div>
            <p style="color: rgb(255, 255, 255);  text-align: center;" class="text-size-24">
                CX ROBOT CONNECT (WUHAN) TECHNOLOGY CO., LTD
            </p>
        </div>
    </div>
    <div v-show="!isMobile" class="div-spacer-h288"></div>

    <div v-show="isMobile" class="div-spacer-h120"></div>
    <!-- Rest of the header content remains the same -->

    <div class="h-c">
        <div class="v-c" style="text-align: center;">
            <h3 class="text-size-64">联系我们</h3>
        </div>

    </div>

    <div class="div-spacer-h48"></div>
    <div class="h-c">
        <div class="v-c">
            <div class="g-c-2-1-1">
                <!-- Contact info section remains the same -->
                <div class="contact">
                    <h3 class="text-size-36">武汉</h3>
                    <div class="div-spacer-h16"></div>
                    <div class="div-spacer-h16"></div>
                    <ul>
                        <li class="text-size-16">电话：400-1586-928</li>
                        <li class="text-size-16">邮箱：cxzl@cx-botcnt.com</li>
                        <li class="text-size-16">公司地址：武汉市东湖新技术开发区软件新城1.1期A区</li>
                    </ul>
                    <div class="div-spacer-h60"></div>
                    <div class="div-spacer-h60"></div>
                    <div class="div-spacer-h60"></div>



                    <h3 class="text-size-36">深圳</h3>
                    <div class="div-spacer-h16"></div>
                    <div class="div-spacer-h16"></div>
                    <ul>
                        <li class="text-size-16">电话：400-1586-928</li>
                        <li class="text-size-16">邮箱：cxzl@cx-botcnt.com</li>
                        <li class="text-size-16">公司地址：深圳市南山区田厦金牛广场</li>
                    </ul>
                    <div class="div-spacer-h60"></div>
                    <div class="div-spacer-h60"></div>
                    <div class="div-spacer-h60"></div>



                    <h3 class="text-size-36">南京</h3>
                    <div class="div-spacer-h16"></div>
                    <div class="div-spacer-h16"></div>
                    <ul>
                        <li class="text-size-16">电话：400-1586-928</li>
                        <li class="text-size-16">邮箱：cxzl@cx-botcnt.com</li>
                        <li class="text-size-16">公司地址：南京市江宁区金源路8号绿地之窗城际空间站H1栋</li>
                    </ul>


                    <div v-show="isMobile" class="div-spacer-h120"></div>
                </div>







                <div style="max-width: 600px;">
                    <div class="form-container">
                        <form @submit.prevent="handleSubmit">
                            <div class="form-group">
                                <label class="form-label required text-size-24">您的姓名</label>
                                <input type="text" class="form-input" v-model="formData.customerName"
                                    placeholder="请输入内容" :class="{ 'error': errors.customerName }" required>
                                <span v-if="errors.customerName" class="error-text">{{ errors.customerName }}</span>
                            </div>

                            <div class="form-group">
                                <label class="form-label required text-size-24">您的公司名称</label>
                                <input type="text" class="form-input" v-model="formData.customerCompany"
                                    placeholder="请输入内容" :class="{ 'error': errors.customerCompany }" required>
                                <span v-if="errors.customerCompany" class="error-text">{{ errors.customerCompany
                                    }}</span>
                            </div>

                            <div class="form-group">
                                <label class="form-label required text-size-24">您的职位</label>
                                <input type="text" class="form-input" v-model="formData.customerTitle"
                                    placeholder="请输入内容" :class="{ 'error': errors.customerTitle }" required>
                                <span v-if="errors.customerTitle" class="error-text">{{ errors.customerTitle }}</span>
                            </div>

                            <div class="form-group">
                                <label class="form-label required text-size-24">您的联系方式（手机或邮箱）</label>
                                <input type="text" class="form-input" v-model="formData.customerContact"
                                    placeholder="手机号或邮箱" :class="{ 'error': errors.customerContact }" required>
                                <span v-if="errors.customerContact" class="error-text">{{ errors.customerContact
                                    }}</span>
                            </div>

                            <div class="form-group">
                                <label class="form-label text-size-24">想咨询的内容</label>
                                <textarea class="form-textarea" v-model="formData.question" placeholder="">
                                </textarea>
                            </div>

                            <button type="submit" class="submit-btn"
                                style="border-radius: 39px;background: rgb(0, 0, 0);width: fit-content;">
                                确认提交
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Success Modal -->
    <div v-if="showSuccessModal" class="modal">
        <div class="modal-content">
            <h3>提交成功</h3>
            <p>已收到您提交的内容，工作人员会尽快与您联系。<br>如有需要，可直接拨打电话400-158-6928。</p>
            <button @click="closeModal('success')">确认</button>
        </div>
    </div>

    <!-- Error Modal -->
    <div v-if="showErrorModal" class="modal">
        <div class="modal-content">
            <h3>提交失败</h3>
            <p>信息提交出现异常，可直接联系客服电话<br>400-158-6928。</p>
            <button @click="closeModal('error')">确认</button>
        </div>
    </div>

    <div class="div-spacer-h288"></div>
    <div class="div-spacer-h288"></div>
</template>

<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import { ref, reactive } from 'vue'
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()


// Form data
const formData = reactive({
    customerName: '',
    customerCompany: '',
    customerTitle: '',
    customerContact: '',
    question: ''
})

// Error states
const errors = reactive({
    customerName: '',
    customerCompany: '',
    customerTitle: '',
    customerContact: ''
})

// Modal states
const showSuccessModal = ref(false)
const showErrorModal = ref(false)

// Validation functions
const validateEmail = (email) => {
    const re = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    return re.test(String(email).toLowerCase())
}

const validatePhone = (phone) => {
    const re = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    return re.test(phone)
}

const validateForm = () => {
    let isValid = true
    // Reset errors
    Object.keys(errors).forEach(key => errors[key] = '')

    // Validate required fields
    if (!formData.customerName.trim()) {
        errors.customerName = '请输入姓名'
        isValid = false
    }

    if (!formData.customerCompany.trim()) {
        errors.customerCompany = '请输入公司名称'
        isValid = false
    }

    if (!formData.customerTitle.trim()) {
        errors.customerTitle = '请输入职位'
        isValid = false
    }

    if (!formData.customerContact.trim()) {
        errors.customerContact = '请输入联系方式'
        isValid = false
    } else if (!validateEmail(formData.customerContact) && !validatePhone(formData.customerContact)) {
        errors.customerContact = '请输入正确的邮箱或手机号'
        isValid = false
    }

    return isValid
}

// Form submission
const handleSubmit = async () => {
    if (!validateForm()) return

    const requestData = {
        customerName: formData.customerName,
        customerCompany: formData.customerCompany,
        customerTitle: formData.customerTitle,
        question: formData.question || ''
    }

    // Determine if contact is email or phone
    if (validateEmail(formData.customerContact)) {
        requestData.customerEmail = formData.customerContact
        requestData.customerPhone = '12345678910' // Default phone as in original code
    } else {
        requestData.customerPhone = formData.customerContact
    }

    try {
        const response = await fetch('http://www.cx-botcnt.com/web-prod-api/customer/question/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })

        if (!response.ok) throw new Error('提交失败')

        const data = await response.json()
        showSuccessModal.value = true
        resetForm()
    } catch (error) {
        console.error('Error:', error)
        showErrorModal.value = true
    }
}

// Reset form
const resetForm = () => {
    Object.keys(formData).forEach(key => formData[key] = '')
    Object.keys(errors).forEach(key => errors[key] = '')
}

// Modal controls
const closeModal = (type) => {
    if (type === 'success') {
        showSuccessModal.value = false
    } else {
        showErrorModal.value = false
    }
}
</script>




<style scoped>
.contact {
    border-right: 0.5px solid rgba(0, 0, 0, 0.1);
    text-align: left;
    /* max-width: 319px; */
    padding-right: 100px;
    margin-right: 100px;

}

@media (max-width: 768px) {
    .contact {
        border-right: 0.0px solid rgba(0, 0, 0, 0.1);
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
        margin-bottom: 20px;
        max-width: 100%;
        padding-right: 0px;
        margin-right: 0px;
    }

}

.banner {


    background-image: url("@/assets/images/官网开发素材/banner/banner-about-profile.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}

.form-container {
    max-width: 500px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;

    color: #333;
}

.form-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
}

.form-textarea {
    width: 100%;
    min-height: 120px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 14px;
    resize: vertical;
}

.submit-btn {
    background-color: #000;
    color: #fff;
    padding: 8px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
}

.submit-btn:hover {
    opacity: 0.9;
}

.required::after {
    content: " (必填)";
    color: #666;
    font-size: 14px;
}


.g-c-2-1-1 {
    grid-template-columns: 1fr 2fr;


}

/* Add new styles for error states and modal */
.error {
    border-color: #ff4444;
}

.error-text {
    color: #ff4444;
    font-size: 12px;
    margin-top: 4px;
}

.modal {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fff;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px;
    border-radius: 10px;
    text-align: center;
}

.modal-content h2 {
    font-size: 24px;
}

.modal-content p {
    font-size: 16px;
    margin-top: 10px;
    color: #333;
}

.modal-content button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-content button:hover {
    background-color: #444;
}
</style>
