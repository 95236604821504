<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 style="color: rgb(255, 255, 255);
                
                
                font-weight: bold;;
                
                
                text-align: left;" class="text-size-72">

                    行业解决方案

                </h1>

                <div class="div-spacer-h16"></div>
                <p style="color: rgb(255, 255, 255);
                
                
                
                
                
                text-align: left;" class="text-size-24">

                    行业场景高精定位定制化解决方案融合了卫星定位技术、惯性定位技术、视觉感知技术、激光雷达技术。为各行业定位需求提供稳定、高效、高精度的信息服务。

                </p>


            </div>





        </div>

    </div>


    <div class="div-spacer-h288"></div>


    <div class="h-c">
        <div class="v-c" style="text-align: center;">
            <h3 class="text-size-64">行业解决方案</h3>

        </div>
    </div>


    <div class="div-spacer-h48"></div>

    <div class="h-c">

        <div class="v-c" style="gap: 16px;">

            <div class="g-c-2-1-1">


                <router-link to="/solution/smart-driving">
                    <SolutionsCard2 str1="智能驾驶汽车" str2="智能驾驶汽车行业是当今科技领域最具活力和发展潜力的行业之一"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/自动驾驶汽车/车1.jpg')" 
                        style="height: 425px;" />
                </router-link>

                <router-link to="/solution/smart-surveying">
                    <SolutionsCard2 str1="智慧测绘仪器" str2="满足机器人定位、姿态、感知精度的需求"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/测绘/测绘2.jpg')" 
                        style="height: 425px;" />
                </router-link>
                <router-link to="/solution/low-speed-vehicle">
                    <SolutionsCard2 str1="低速无人车" str2="厘米级的全域持续定位服务"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/低速无人车/无人车2.jpg')" 
                        style="height: 425px;" />
                </router-link>

                <router-link to="/solution/smart-robot">
                    <SolutionsCard2 str1="智能机器人" str2="满足机器人定位、姿态、感知精度的需求"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/智能机器人/机器人狗.jpg')" 
                        style="height: 425px;" />
                </router-link>
                <router-link to="/solution/agricultural-machinery">
                    <SolutionsCard2 str1="自动化农机" str2="实现高效率、高精度、高质量的农业作业"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/自动化农机/农机车.jpg')" 
                        style="height: 425px;" />
                </router-link>

                <router-link to="/solution/engineering-machinery">
                    <SolutionsCard2 str1="自动化工程机械" str2="满足定位、姿态以及、复杂环境信息高精度感知需求"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/工程机械自动化/工程车2.jpg')" 
                        style="height: 425px;" />
                </router-link>
                <router-link to="/solution/uav">
                    <SolutionsCard2 str1="无人飞行器 " str2="解决精度稳定性不足， 信号干扰遮蔽，姿态控制精度等技术难题。  "
                        :src="require('@/assets/images/官网开发素材/行业解决方案/无人机/无人机方图2.jpg')" 
                        style="height: 425px;" />
                </router-link>

                <router-link to="/solution/port-shipping">
                    <SolutionsCard2 str1="港口船舶" str2="精准的定位定向，实现精准港口船舶、人员、车辆管理。"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/港口/港口2.jpg')" 
                        style="height: 425px;" />
                </router-link>
            </div>



        </div>


    </div>




<div class="div-spacer-h288"></div>

</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner-news-detail.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}

a{
    width: 100%;
}

</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import SolutionsCard2 from '@/components/SolutionsCard2.vue';







</script>