<template>



    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

                <p class="text-size-64" style="color: rgb(0, 0, 0);
                    
                    

                    
                    
                    text-align: left;">
                    惯性组合导航系统

                </p>

                <h1 class="text-size-96" style="color: rgb(0, 0, 0);
                    
                    font-weight: bold;
                    text-wrap: nowrap;
                    text-align: left;">
                    CXGY-150<br>INS-BOX双天线
                </h1>


                <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                    CXGY-150
                    INS-BOX是一款结合了全球卫星导航系统GNSS和惯性IMU两者优势，同时融合了车辆信息（轮速、挡位等），通过紧耦合的组合导航算法提供实时连续的高精度、高可靠性的厘米级定位、车辆速度和位姿等信息的高精定位组合导航系统。适用于智能驾驶、机器人、无人车、卫星组合导航等领域。
                </p>


            </div>






        </div>

    </div>


    <div v-show="isMobile" class="h-c">
        <div class="div-spacer-h288"></div>
        <div class="v-c">

            <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                CXGY-150
                INS-BOX是一款结合了全球卫星导航系统GNSS和惯性IMU两者优势，同时融合了车辆信息（轮速、挡位等），通过紧耦合的组合导航算法提供实时连续的高精度、高可靠性的厘米级定位、车辆速度和位姿等信息的高精定位组合导航系统。适用于智能驾驶、机器人、无人车、卫星组合导航等领域。
            </p>
</div>
        <div class="div-spacer-h288"></div>
    </div>    <div v-show="!isMobile" class="div-spacer-h288"></div>





    <div class="h-c">

        <CombinedTitle title="惯性组合导航系统" shortTitle="" subTitle="CXGY-150 INS-BOX(Dual-Antenna )" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard
                :src="require('@/assets/images/官网开发素材/工规产品/CXGY-150 INS-BOX双天线/CXGY-150 INS-BOX双天线pbox-old.3.jpg')"
                :radius="28" style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                
                    



                    
                    
                    text-align: left;">
                    产品构成
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">





                    <li>GNSS（全球卫星定位系统）</li>
                    <li>RTK（实时动态载波相位差分技术）</li>
                    <li>IMU （惯性测量单元）</li>
                    <li>ETH （网络单元）</li>
                    <li>接口子系统（RS232/422，CAN）</li>
                </ul>

            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/工规产品/CXGY-150 INS-BOX双天线/CXGY-150 INS-BOX双天线pbox.2.jpg')"
                :radius="28" style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    产品特点
                </h3>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    font-weight: bold;
                    
                    text-align: left;">
                    本产品具有体积小、环境适应性强、低功耗、时间同步等特点。
                </p>

                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                
                    
                    text-align: left;">

 



                    <li>陀螺零偏不稳定性小于1.8°/h，加速度计零偏不稳定性小于15μg</li>
                    <li>器件满足AEC-Q100认证</li>
                    <li>全温标定（Step:10°C）</li>
                    <li>角速率测量范围：±300°/s</li>
                    <li>加速度计测量范围：±6g</li>
                    <li>工作温度范围：−40°C～+105°C</li>
                    <li>支持高精定位数据输出、卫星原始数据输出、原始IMU数据输出、 PPS输出功能</li>
                    <li>支持CANFD总线OTA版本升级功能</li>
                    <li>支持上电自检功能</li>
                    <li>支持实时在线标定功能（姿态角、杆臂值、轮速尺度）</li>
                    <li>支持轮速、档位等数据可按车型定制功能</li>
                    <li>支持接收对手件（域控）的时间授时功能</li>
                    <li>支持GNSS双天线全频定位定向功能、支持4G网络通信</li>
                    
                </ul>



            </div>


        </div>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <p class="text-size-64" style="text-align: center;
            color: rgb(0, 0, 0);
            
            
            font-weight: bold;

            
            text-align: center;">
            产品性能参数
        </p>

    </div>

    <div class="div-spacer-h80"></div>




    <div class="h-c">
        <div class="v-c">

            <SpecTable :specData="[
 {
   key: '卫星据止下惯导递推综合性能',
   tableData: [
     {
       title: '位置精度',
       contentList: ['2‰ (1Km or 2min) 2σ']
     },
     {
       title: '航向角精度', 
       contentList: ['0.1° (1Km or 2min) 2σ']
     },
     {
       title: '刷新频率&PPS授时精度',
       contentList: ['100Hz, 30ns']
     }
   ]
 },
 {
   key: '陀螺指标',
   tableData: [
     {
       title: '零偏不稳定性(Alan方差)',
       contentList: ['条件：All axes,25°C', '标称值：<1.8', '单位：°/h']
     }
   ]
 },
 {
   key: '加速度计指标',
   tableData: [
     {
       title: '零偏不稳定性(Allan 方差)',
       contentList: ['条件：15', '标称值：15', '单位：μg']
     }
   ]
 }
]" />

        </div>
    </div>


    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c" style="justify-content: center;">
            <router-link to="/about/contact" style="text-align: center; width: 100%;">
        <AdapButtomBlack text="获取详细产品信息" />
      </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            font-weight: bold;
            text-align: center;">
            应用场景
        </h3>


    </div>
    <div class="div-spacer-h80"></div>


    <div class="h-c">

        <div v-show="!isMobile" class="v-c">
            <ScrollingCards :items="items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards :items="items" />
        </div>





    </div>


    <div class="div-spacer-h288"></div>





</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner工规产品/科技正圆+cxgy150.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}





</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards from '@/components/ScrollingCards.vue';
import mScrollingCards from '@/components/mScrollingCards.vue';
const items = [
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/无人车4.jpg'), title: '低速无人车', url: '/solution/low-speed-vehicle' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/机器人.jpg'), title: '智能机器人', url: '/solution/smart-robot' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/矿区2.jpg'), title: '工程机械自动化', url: '/solution/engineering-machinery' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/智慧农机.jpg'), title: '自动化农机', url: '/solution/agricultural-machinery' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/无人机3.jpg'), title: '无人飞行器', url: '/solution/uav' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/港口.jpg'), title: '港口船舶', url: '/solution/port-shipping' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/自动驾驶.jpg'), title: '智能驾驶汽车', url: '/solution/smart-driving' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/测绘1.jpg'), title: '智慧测绘仪器', url: '/solution/smart-surveying' },

];import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import FeatureCard from '@/components/FeatureCard.vue';
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtom from '@/components/AdapButtom.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>