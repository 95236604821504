/* 2产品服务-卫惯系列/车规/cpfusion */


<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

                <p class="text-size-64" style="color: rgb(0, 0, 0);
            
            font-weight: bold;

            text-wrap: nowrap;
            
            text-align: left;">
                    卫惯天线新组合<br>
                    高精定位新趋势
                </p>


                <div class="div-spacer-h48"></div>


                <h1 class="text-size-36" style="color: rgb(0, 0, 0);
            font-weight:400;
            text-wrap: nowrap;
            text-align: left;">
                    微型高精定位智联终端
                </h1>
                <h1 class="text-size-36" style="color: rgb(0, 0, 0);
            
            font-weight: bold;
            text-wrap: nowrap;
            text-align: left;">
                    CXP-FUSION
                </h1>
                <div class="div-spacer-h48"></div>

                <p v-show="!isMobile" class="text-size-16" style="color: rgb(89, 89, 89);
            
            
            text-align: left;">
                    CXP-FUSION微型高精定位智联终端，是一款极具突破性的卫惯组合导航系统与车辆天线一体化产品。结合了全球卫星导航系统GNSS和惯性IMU两者优势，通过紧耦合的组合导航算法提供实时连续的高精度、高可靠性的厘米级定位、车辆速度和位姿等信息的一体化智能天线。CXP-FUSION在将GNSS、IMU和天线集成的情况下，能够整合多种功能于一体，大大减小系统的体积和重量，提高系统的集成度和可靠性，避免了三者间工作环境的干扰，完美保留了天线和卫惯组合导航系统各自原本的最优性能。
                </p>


            </div>






        </div>

    </div>

    <div v-show="isMobile" class="h-c">
        <div class="div-spacer-h288"></div>
        <div class="v-c">

            <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                FUSION微型高精定位智联终端，是一款极具突破性的卫惯组合导航系统与车辆天线一体化产品。结合了全球卫星导航系统GNSS和惯性IMU两者优势，通过紧耦合的组合导航算法提供实时连续的高精度、高可靠性的厘米级定位、车辆速度和位姿等信息的一体化智能天线。CXP
            </p>
        </div>
        <div class="div-spacer-h288"></div>
    </div>
    <div v-show="!isMobile" class="div-spacer-h288"></div>






    <div class="h-c">

        <GroupNameCard title="微型高精定位智联终端" describe="CXP-FUSION" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/cxpfusion1.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    



                    



                    
                    
                    text-align: left;">
                    产品构成
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    
                    
                    text-align: left;
                    list-style-position: inside;">
                    <li>GNSS（全球卫星定位系统）</li>
                    <li>天线</li>
                    <li>IMU （惯性测量单元）</li>

                </ul>

            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/cxpfusion2.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    产品特点
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    
                    
                    text-align: left;
                    list-style-position: inside;">
                    <li>器件满足AEC-Q100认证</li>
                    <li>符合ISO26262 ASIL-B功能安全设计</li>
                    <li>全温标定（Step:10°C）</li>
                    <li>角速率测量范围：±250°/s （可选125/500/1000）</li>
                    <li>加速度计测量范围：±4g（可选2/8/16）</li>
                    <li>工作温度范围：−40°C～+105°C</li>
                    <li>支持高精定位数据输出、卫星原始数据输出、原始IMU数据输出、PPS输出功能</li>
                    <li>支持OTA版本升级功能</li>
                    <li>支持低功耗模式和哨兵模式（低功耗下只输出IMU数据）</li>
                    <li>支持上电自检功能</li>
                    <li>支持给收对手件（域控）的时间授时功能</li>

                </ul>


            </div>


        </div>
    </div>



    <div class="div-spacer-h288"></div>


    <div class="h-c">

        <GroupNameCard title="6大创新优势" describe="Innovation Advantage" />

    </div>


    <div class="div-spacer-h48"></div>





    <div class="h-c">
        <div class="v-c">
            <div class="g-c-2-1-1" style="gap: 40px;">
                <AdvantageCard2 title="优势一：成本更低" describe="将传统卫惯组合定位模块和天线集成一体，
    有效减少零部件采购环节、减轻库存压力、减少对车辆空间的占用及安装环节，
    有效提高生产效率，成本较市场主流P-BOX方案+天线单独采购可下降20%。"
                    :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=成本低.svg')" />
                <AdvantageCard2 title="优势二：体积更小"
                    describe="产品的尺寸（宽×深×高）约为<75×75×26mm，重量仅120g，小于市面绝大多数P-BOX产品，可以与车辆天线一体化安装，节约空间，使整车结构布局更加灵活简洁合理。"
                    :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=体积更小.svg')" />
                <AdvantageCard2 title="优势三：形态更活"
                    describe="产品形态可以根据主机厂车型功能和结构需求定制，满足AUTORSAR软件架构，支持多种接口、支持车载以太网，CANFD、RS232串口等、支持gPTP时间同步，灵活适配。"
                    :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=形态更活.svg')" />
                <AdvantageCard2 title="优势四：性能更强"
                    describe="结合GNSS和惯性IMU两者优势，同时集成的车辆天线能获得更稳定的信号和更大增益。在有限的空间内，克服了独立天线和IMU、GNSS集成互相干扰的难点，确保产品在不受干扰的工作环境下性能更优。"
                    :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=性能更强.svg')" />
                <AdvantageCard2 title="优势五：精度更高"
                    describe="内置的高精度IMU均经过全温标定,通过离线标定IMU系统误差和在线实时估计IMU随机误差，改善了零偏不稳定性和比例因子误差等关键指标，航位推算精度大幅提升。采用紧耦合导航算法，配合高精度IMU，提高了在城市峡谷、林荫路、高架下等复杂场景下的定位精度。"
                    :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=精度更高.svg')" />
                <AdvantageCard2 title="优势六：安全更优"
                    describe="内置功能安全ASIL B等级高精度IMU(全温标定)，满足AEC-Q100测试标准，达到IP67 或更高的防水防尘等级，以确保汽车的安全性和稳定性。"
                    :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=安全.svg')" />


            </div>

        </div>
    </div>
    <div class="div-spacer-h288"></div>
    <div class="h-c">
        <div class="v-c">

            <h3 class="text-size-64" style="color: rgb(0, 0, 0);
                
                
                
                font-weight: bold;
                
                text-align: center;">
                产品性能参数
            </h3>



        </div>


    </div>
    <div class="div-spacer-h48"></div>

    <div class="h-c">
        <div class="v-c">



            <SpecTable :specData="[
                {
                    key: '整机参数',
                    tableData: [
                        {
                            title: '数据接口',
                            contentList: [
                                'RTCM 输入及 GGA 请求数据输出',
                                'INS、GNSS 和 IMU 数据输出'
                            ]
                        },
                        {
                            title: '休眠模式',
                            contentList: ['支持']
                        },
                        {
                            title: '工作电压',
                            contentList: ['9-16V']
                        },
                        {
                            title: '工作电流',
                            contentList: ['≤0.2A@12V']
                        },
                        {
                            title: '静态电流',
                            contentList: ['≤1mA']
                        },
                        {
                            title: '工作温度',
                            contentList: ['-40 ~ 85℃（-40 ~ 105℃可选）']
                        },
                        {
                            title: '尺寸：宽x深x高（不算挂耳）',
                            contentList: ['99x99x26mm']
                        },
                        {
                            title: '重量（裸机）',
                            contentList: ['157±20g']
                        },
                        {
                            title: '防护等级',
                            contentList: ['IP66']
                        }
                    ]
                },
                {
                    key: '天线性能指标',
                    tableData: [
                        {
                            title: '频点',
                            contentList: [
                                'GPS L1 C/A, L1C*, L5',
                                'GLONASS L1*',
                                'BeiDou B1I, B1C*, B2b',
                                'Galileo E1, E5a',
                                'NAVIC L5*',
                                'QZSS L1, L5',
                                'SBAS*'
                            ]
                        },
                        {
                            title: '轴比',
                            contentList: [
                                '≤3 dB，法向轴比',
                                '≤4 dB，倾角20°方向轴比'
                            ]
                        },
                        {
                            title: '增益',
                            contentList: [
                                '≥3.5dBi @L1',
                                '≥2.5dBi @L5+B2b'
                            ]
                        },
                        {
                            title: '相位中心',
                            contentList: ['±10mm']
                        },
                        {
                            title: '噪声系数',
                            contentList: ['< 2.5dB']
                        }
                    ]
                },
                {
                    key: 'GNSS性能指标',
                    tableData: [
                        {
                            title: 'TIFF',
                            contentList: [
                                '冷启动：26S',
                                '热启动：2S',
                                '重捕获：2S'
                            ]
                        },
                        {
                            title: '水平定位精度（1σ）',
                            contentList: [
                                '单点定位：1m CEP (open sky)',
                                'RTK 动态定位精度：10cm+1ppm (open sky)',
                                'RTK 静态定位精度：2cm+1ppm (open sky)'
                            ]
                        },
                        {
                            title: '测速精度(1σ)',
                            contentList: ['0.03m/s']
                        },
                        {
                            title: '1PPS精度(RMS)',
                            contentList: ['20ns']
                        },
                        {
                            title: '数据更新率',
                            contentList: ['10Hz']
                        },
                        {
                            title: '数据格式',
                            contentList: [
                                'RTCM：支持',
                                'NMEA-0183：支持',
                                'hicore Protocol：支持'
                            ]
                        }
                    ]
                },
                {
                    key: 'IMU性能指标',
                    tableData: [
                        {
                            title: '量程',
                            contentList: [
                                '陀螺仪：±250（125/250/500/1000）°/S',
                                '加速度计：±4（2/4/8/16）g'
                            ]
                        },
                        {
                            title: '零偏不稳定性',
                            contentList: [
                                '陀螺仪：≤3°/h',
                                '加速度计：≤0.05mg'
                            ]
                        },
                        {
                            title: '角度随机游走',
                            contentList: [
                                '陀螺仪：≤0.21°/√h',
                                '加速度计：/'
                            ]
                        },
                        {
                            title: '速度随机游走',
                            contentList: [
                                '陀螺仪：/',
                                '加速度计：≤0.04m/s/√hr'
                            ]
                        }
                    ]
                },
                {
                    key: '卫星拒止下惯导递推综合性能',
                    tableData: [
                        {
                            title: '位置精度',
                            contentList: ['2‰D @(1km or 60S)2σ']
                        },
                        {
                            title: '航向角精度',
                            contentList: ['0.2°@(1km or 60S)2σ']
                        },
                        {
                            title: '速度精度',
                            contentList: ['0.1m/s 2σ']
                        }
                    ]
                }
            ]" />






        </div>
    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c">
        <router-link to="/about/contact" style="text-align: center; width: 100%;">
            <AdapButtomBlack text="获取详细产品信息" />
        </router-link>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            font-weight: bold;
            text-align: center;">
            应用场景
        </h3>


    </div>
    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div v-show="!isMobile" class="v-c">
            <ScrollingCards2 :items="ScrollingCards2_items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards2 :items="ScrollingCards2_items" />
        </div>
    </div>





    <div class="div-spacer-h288"></div>






</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/车规产品/cxfusion/banner.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}








@media (max-width: 768px) {
    
}
</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()

import SpecTable from '@/components/SpecTable.vue';
import GroupNameCard from '@/components/GroupNameCard.vue';
import PictureCard from '@/components/PictureCard.vue';
import AdvantageCard2 from '@/components/AdvantageCard2.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';


import ScrollingCards2 from '@/components/ScrollingCards2.vue';
import mScrollingCards2 from '@/components/mScrollingCards2.vue';
const ScrollingCards2_items = [
    { image: require('@/assets/images/官网开发素材/inertia/p2.jpg'), title: '隧道内岔路口', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p3.jpg'), title: '高架桥下', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p4.jpg'), title: '地下停车场', desc: '无卫星信号' },
    { image: require('@/assets/images/官网开发素材/inertia/p6.jpg'), title: '城市峡谷', desc: '卫星信号弱，多路劲效应影响' },
    { image: require('@/assets/images/官网开发素材/inertia/p5.jpg'), title: '复杂立体交通', desc: '卫星信号无/弱' }
];

</script>