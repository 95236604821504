<!-- 解决方案-港口船舶 -->


<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255);
                
                
                font-weight: bold;;
                
                
                text-align: left;">

                    行业解决方案

                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(255, 255, 255);
                
                
                
                
                
                text-align: left;">

                    行业场景高精定位定制化解决方案融合了卫星定位技术、惯性定位技术、视觉感知技术、激光雷达技术。为各行业定位需求提供稳定、高效、高精度的信息服务。

                </p>


            </div>





        </div>

    </div>
<div class="div-spacer-h288"></div>

    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/港口/港口.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 275px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    港口船舶
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    随着科技的飞速发展，港口船舶自动化行业取得了显著的进步。自动化技术在港口装卸、船舶导航、货物管理等方面得到广泛应用。港口船舶、人员、车辆定位定向是智慧港口系统中的重要组成部分，精准的定位定向，帮助实现精准的港口船舶、人员、车辆管理。
                </p>
            </div>

        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c" >
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: center;">

                    港口船舶-行业痛点

                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">

                    传统的港口船舶、人员、车辆定位主要依赖GNSS全球定位系统，而多系统多频的全球定位系统，虽然定位精度较高，但是对于信息安全方面，存在不小的挑战。另外仅仅依靠卫星导航定位，在港口多集装箱和遮挡的场景下，会经常发生定位不准确的情况。

                    INS惯性组合导航系统，在卫星信号丢失的情况下，通过惯导数据推算，提供持续稳定的高精度定位。
                    船舶定向方面，传统船用罗经因地磁信号的易受干扰性和较低的准确性，使得传统罗经在确定船身朝向方面存在局限，卫星导航与惯性导航技术相结合，通过融合算法提高导航精度。
                </p>

            </div>

        </div>

    </div>


    <div class="div-spacer-h288"></div>




    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/港口/港口2.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    产品优势
                </h3>






                








                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    
                    
                    text-align: left;
                    list-style-position: inside;">
                    <li>精度高</li>
                    <li>融合BDS、IMU、磁力计</li>
                    <li>成熟的姿态、航向算法</li>
                    <li>高性价比</li>
                    <li>高可靠性</li>
                    <li>全面的技术支持服务</li>
                    
                   

                </ul>
            </div>

        </div>
    </div>
    <div class="div-spacer-h288"></div>



    <div class="h-c" >
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            
            
            
            
            
            text-align: center;">


            相关产品

        </h3>

    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c">


        <RelatedScrollingCards3 :products="RelatedScrollingCards3Products" :visibleImages="2" />

    </div>
    <div class="div-spacer-h288"></div>









</template>



<style scoped>
.banner {

    
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("@/assets/images/官网开发素材/banner/行业解决方案banner/港口.jpg");
    z-index: -1;
    
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果

import PictureCard from '@/components/PictureCard.vue';
import RelatedScrollingCards3 from '@/components/RelatedScrollingCards3.vue';
const RelatedScrollingCards3Products = [
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGNM121SA/单北斗GNSS.5.jpg"),
    title: "CXGY-B10",
    description: "北斗定位模组",
    link: "/product/industry/cxgyb10"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXDWK101SA/小夜壶2.jpg"),
    title: "CXGY-B110",
    description: "北斗移动定位终端",
    link: "/product/industry/cxgyb110"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXBSD121SA/车载定位终端.7.jpg"),
    title: "CXGY-B120",
    description: "车载精准定位终端",
    link: "/product/industry/cxgyb120"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXBSD131SA/单北斗.3.jpg"),
    title: "CXGY-B130",
    description: "高精度位姿测量装置",
    link: "/product/industry/cxgyb130"
  }
  
]




</script>