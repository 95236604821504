<template>



    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

                <p class="text-size-64" style="color: rgb(0, 0, 0);
                    
                    

                    
                    
                    text-align: left;">
                    GNSS模组
                </p>

                <h1 class="text-size-96" style="color: rgb(0, 0, 0);
                    
                    font-weight: bold;
                    text-wrap: nowrap;
                    text-align: left;">
                    GNM11
                </h1>


                <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                    GNM11模块基于高性能车规级GNSS SoC芯片研制，采用54pin LGA封装，内置高精度测量引擎、导航引擎，可提供1040跟踪通道、跟踪处BDS、GPS、GLONASS、Galileo、QZSS
                    和NavIC等导航系统的多种信号频点。
                </p>


            </div>






        </div>

    </div>


    <div v-show="isMobile" class="h-c">
        <div class="div-spacer-h288"></div>
        <div class="v-c">

            <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                GNM11模块基于高性能车规级GNSS SoC芯片研制，采用54pin LGA封装，内置高精度测量引擎、导航引擎，可提供1040跟踪通道、跟踪处BDS、GPS、GLONASS、Galileo、QZSS
                和NavIC等导航系统的多种信号频点。
            </p>
</div>
        <div class="div-spacer-h288"></div>
    </div>    <div v-show="!isMobile" class="div-spacer-h288"></div>





    <div class="h-c">

        <CombinedTitle title="GNM11" shortTitle="" subTitle="GNM11" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/模组/gnm11/GNM11-1.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    



                    



                    
                    
                    text-align: left;">
                    产品构成
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">

                    <li>GNSS（全球卫星定位系统）</li>
                    <li>RTK（实时动态载波相位差分技术）</li>
                    <li>接口子系统（以太网、UART, I²C, CAN）</li>
                </ul>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    font-weight: bold;
                    
                    text-align: left;">
                    搭载先进处理器系统，内置双精度浮点运算处理单元，支持高精度高频率定位定向结果输出，全系统全频点 RTK 解算，能够有效地应对卫星信号干扰、遮挡、多路径等苛刻环境，提供连续、实时、可信的高精度定位结果。
                </p>
            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/模组/gnm11/GNM11-2.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    产品特点
                </h3>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">
                    本产品支持全系统全频点GNSS信号接受，可以应对复杂电磁环境的干扰抑制，基于功能安全ASIL-B设计。
                </p>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    




                    











                    
                    text-align: left;">

                    <li>支持全系统全频点GNSS信号接受</li>
                    <li>可以应对复杂电磁环境的干扰抑制</li>
                    <li>基于功能安全ASIL-B设计</li>

                </ul>



            </div>


        </div>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <p class="text-size-64" style="text-align: center;
            color: rgb(0, 0, 0);
            
            
            font-weight: bold;

            
            text-align: center;">
            产品性能参数
        </p>

    </div>

    <div class="div-spacer-h80"></div>




    <div class="h-c">
        <div class="v-c">

            <SpecTable :specData="[
                {
                    key: '规格指标',
                    tableData: [
                        {
                            title: '系统',
                            contentList: ['全频全系统：GPS、BDS、GLO、GAL、QZSS、NavIC、SBAS']
                        },
                        {
                            title: '通道数',
                            contentList: ['1040']
                        },
                        {
                            title: '信号频段',
                            contentList: [
                                'BDS: B1I、B1C、B2I、B2a、B2b*',
                                'GPS: L1 C/A、L5、L2C',
                                'Galileo: E1、E5a、E5b',
                                'GLONASS: G1*',
                                'QZSS: L1 C/A、L2C、L5',
                                'NavIC: L5*',
                                'SBAS: L1*'
                            ]
                        },
                        {
                            title: '对外接口',
                            contentList: [
                                'SPI*1',
                                'UART *3',
                                'PPS *1',
                                'EVENT *1',
                                'I²C *1',
                                'CAN *2'
                            ]
                        },
                        {
                            title: '水平定位精度',
                            contentList: [
                                '单点 <1.5 m',
                                'RTK 0.7cm+1ppm'
                            ]
                        },
                        {
                            title: '高程定位精度',
                            contentList: [
                                '单点 <2.5m',
                                'RTK 1.5cm+1ppm'
                            ]
                        },
                        {
                            title: '输出频率',
                            contentList: [
                                'GNSS观测量 20HZ',
                                'GNSS数据频率 20HZ'
                            ]
                        },
                        {
                            title: '首次定位时间',
                            contentList: [
                                '冷启动 ≤30s',
                                '热启动 ≤2s'
                            ]
                        },
                        {
                            title: 'RTK初始化时间',
                            contentList: ['<5s']
                        },
                        {
                            title: '失锁重新时间',
                            contentList: ['<2s']
                        },
                        {
                            title: '授时速度（RMS）',
                            contentList: ['10ns']
                        },
                        {
                            title: '速度精度',
                            contentList: ['0.05m/s']
                        }
                    ]
                },
                {
                    key: '物理和电气',
                    tableData: [
                        {
                            title: '型号',
                            contentList: ['GNM11']
                        },
                        {
                            title: '尺寸',
                            contentList: ['22*17*3mm']
                        },
                        {
                            title: '封装',
                            contentList: ['LGA']
                        },
                        {
                            title: '重量',
                            contentList: ['3g']
                        },
                        {
                            title: '功率',
                            contentList: ['400mW']
                        },
                        {
                            title: '供电范围',
                            contentList: ['3.0～3.6v']
                        }
                    ]
                },
                {
                    key: '环境及其他',
                    tableData: [
                        {
                            title: '工作温度',
                            contentList: ['-40℃～105℃']
                        },
                        {
                            title: '储存温度',
                            contentList: ['-55℃～125℃']
                        }
                    ]
                }
            ]" />

        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c" style="justify-content: center;">
            <router-link to="/about/contact" style="text-align: center; width: 100%;">
        <AdapButtomBlack text="获取详细产品信息" />
      </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            font-weight: bold;
            text-align: center;">
            应用场景
        </h3>


    </div>
    <div class="div-spacer-h80"></div>


    <div class="h-c">

        <div v-show="!isMobile" class="v-c">
            <ScrollingCards2 :items="ScrollingCards2_items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards2 :items="ScrollingCards2_items" />
        </div>





    </div>


    <div class="div-spacer-h288"></div>





</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner车规产品/科技正圆+GNM11.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}





</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards2 from '@/components/ScrollingCards2.vue';
import mScrollingCards2 from '@/components/mScrollingCards2.vue';
const ScrollingCards2_items = [
    { image: require('@/assets/images/官网开发素材/inertia/p2.jpg'), title: '隧道内岔路口', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p3.jpg'), title: '高架桥下', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p4.jpg'), title: '地下停车场', desc: '无卫星信号' },
    { image: require('@/assets/images/官网开发素材/inertia/p6.jpg'), title: '城市峡谷', desc: '卫星信号弱，多路劲效应影响' },
    { image: require('@/assets/images/官网开发素材/inertia/p5.jpg'), title: '复杂立体交通', desc: '卫星信号无/弱' }
]; import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import FeatureCard from '@/components/FeatureCard.vue';
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtom from '@/components/AdapButtom.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>