<template>
  <div class="div-spacer-h48"></div>
  <div class="h-c">
    <div class="v-c">
      <div class="news-container">
        <div class="news-grid">
          <div v-for="news in NewsList" :key="news.title" class="news-card" @click="openNewsUrl(news.url)">
            <div class="news-image">
              <img :src="news.figure" :alt="news.title">
            </div>
            <div class="news-content">
              <div>
                <h3 class="news-title">{{ news.title }}</h3>
                <p class="news-summary">{{ news.summary }}</p>
              </div>

              <div class="news-footer">
                <span class="news-date">{{ news.release }}</span>
                <span class="news-link">查看详情 ></span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="div-spacer-h48"></div>
</template>

<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
const openNewsUrl = (url) => {
  window.open(url, '_blank')
}

const NewsList = [
  {
    title: '诚芯智联CXGY-15系列',
    summary: '车规级惯性传感，赋能低速无人驾驶',
    figure: require('@/assets/images/官网开发素材/news/WechatIMG3.jpg'),
    url: 'https://mp.weixin.qq.com/s/0Yxso4hS5DbXEckuvBFdIQ',
    release: '2024年11月7日',
    content: [

    ]
  },
  {
    title: '诚芯智联CXP-FUSION',
    summary: '降本增效六大优势 诚芯智联CXP-FUSION',
    figure: require('@/assets/images/官网开发素材/news/WechatIMG45.jpg'),
    url: 'https://mp.weixin.qq.com/s/WHoeRm0KRU999slbeOrocw',
    release: '2024年09月23日',
    content: [

    ]
  },
  {
    title: 'CXP-FUSION',
    summary: '高精定位方案全新选择，CXP-FUSION值得期待',
    figure: require('@/assets/images/官网开发素材/news/WechatIMG46.jpg'),
    url: 'https://mp.weixin.qq.com/s/qvMMoKbJwOr28BQ2J6cIeA',
    release: '2024年09月13日',
    content: [

    ]
  },


  {
    title: '活动快讯',
    summary: '诚芯智联参加智能汽车域控制器与中央计算平台创新峰会',
    figure: require('@/assets/images/官网开发素材/news/hd1.png'),
    url: 'https://mp.weixin.qq.com/s/FwH9D76nwueE_nJ7tbW0bw',
    release: '2024年09月04日',
    content: [

    ]
  },

  {
    title: '惯导黑科技，助力中国奥运夺金!',
    summary: '惯导黑科技，助力中国奥运夺金!巴黎奥运会正如火如荼地进行着，中国奥运健儿屡创佳绩。',
    figure: require('@/assets/images/官网开发素材/news/n1.jpg'),
    url: 'https://mp.weixin.qq.com/s/G51DKHHxxnCR5VVhhdaLhw',
    release: '2024年08月09日',
    content: [
    ]
  },
  {
    title: '开启发展新征程，迈向未来新篇章!',
    summary: '诚芯智联 2024 年中总结暨青干团训练营活动：诚芯智联，勇往直前!',
    figure: require('@/assets/images/官网开发素材/news/n2.jpg'),
    url: 'https://mp.weixin.qq.com/s/Twr7QiX2kioIwiEXPRqpwQ',
    release: '2024年07月24日',
    content: [
    ]
  }
  ,
  {
    title: '开启发展新征程，迈向未来新篇章!',
    summary: '诚芯智联L4定点突破！国内首个车道级无人载重配送车定点!',
    figure: require('@/assets/images/官网开发素材/news/n3.jpg'),
    url: 'https://mp.weixin.qq.com/s/oe3jCoUdhSB8eV6_5rC-cQ',
    release: '2024年06月26日',
    content: [
    ]
  },
  {
    title: '主流车企 定点量产',
    summary: '主流车企 定点量产',
    figure: require('@/assets/images/官网开发素材/news/dd1.png'),
    url: 'https://mp.weixin.qq.com/s/KgW9TFaZLGF-0B578mTJmg',
    release: '2024年05月06日',
    content: [
    ]
  },
  {
    title: '诚芯智联再获银行授信支持',
    summary: '诚芯智联再获银行授信支持',
    figure: require('@/assets/images/官网开发素材/news/qy1.png'),
    url: 'https://mp.weixin.qq.com/s/Eu-WqzMOCQx43xeHgpPc4A',
    release: ' 2024年04月29日',
    content: [
    ]
  },
];





</script>


<style scoped>
.news-container {


  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;


}

.news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin: 0 auto;
}

.news-card {
  display: grid;
  grid-template-rows: 2fr 1.6fr;
  max-height: 360px;
  background: #fff;
  border-radius: var(--cx-radius);
  /* box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.news-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.news-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.news-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.news-card:hover .news-image img {
  transform: scale(1.05);
}

.news-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.news-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 12px 0;
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-summary {
  font-size: 14px;
  color: #666;
  margin: 0 0 16px 0;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-footer {
  display: flex;
  justify-content: space-between;

  align-items: center;
  padding-top: 12px;
  border-top: 1px solid #eee;
}

.news-date {
  font-size: 12px;
  color: #999;
  text-align: left;
}

.news-link {
  font-size: 14px;
  color: #2196f3;
  font-weight: 500;
  text-align: right;
}

/* 响应式布局 */
@media screen and (max-width: 768px) {
  .news-grid {
    grid-template-columns: 1fr;
    gap: 16px !important;/* 抵消首页的全局gap */
    
  }

  .news-card {
    max-width: 100%;
  }
}
</style>
