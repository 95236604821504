<!-- 产品服务-算法服务 -->

<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 935px;">
                <h1 class="text-size-72" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    诚芯算法开发平台
                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    CX-INS SDK 是诚芯智联开发的专门用于高精定位导航的捷
                    联惯导及卫惯组合导航系统的算法库，包括从器件标定及数据
                    预处理，到松/紧耦合组合导航的全套算法，可以满足各类高精定位需求。
                </p>


            </div>





        </div>

    </div>



    <div class="bgc div-spacer-h288"></div>


    <div class="bgc h-c">

        <GroupNameCard title="特色与优势" describe="Features and advantages" />

    </div>

    <div class="bgc div-spacer-h80"></div>

    <div class="bgc h-c">
        <div v-show="!isMobile" class="v-c">
            <div class="g-c-5-1-1">
                <AdvantageCard3 title="性能优秀" describe="20年综合智能算法深度挖掘设备的极限"
                    :src="require('@/assets/images/官网开发素材/算法/1.svg')" />
                <AdvantageCard3 title="便捷开发" describe="提供Android/Linux的算法SDK封装，便于集成开发"
                    :src="require('@/assets/images/官网开发素材/算法/2.svg')" />
                <AdvantageCard3 title="适用性广" describe="对车载、无人机、机器人、农业机械等多种典型场景进行特殊优化保证最优性能"
                    :src="require('@/assets/images/官网开发素材/算法/3.svg')" />
                <AdvantageCard3 title="硬件兼容" describe="兼容市面主流gnss/imu产品，提供对应配置文件，算法一键导入"
                    :src="require('@/assets/images/官网开发素材/算法/4.svg')" />
                <AdvantageCard3 title="定制服务" describe="根据特殊需求提供高效可靠贴心的定制化服务"
                    :src="require('@/assets/images/官网开发素材/算法/5.svg')" />
            </div>
        </div>
        <div v-show="isMobile" class="v-c">
            <div class="g-c-5-1-1" style="z-index: 1;">
                <AdvantageCard2 title="性能优秀" describe="20年综合智能算法深度挖掘设备的极限"
                    :src="require('@/assets/images/官网开发素材/算法/1.svg')" />
                <AdvantageCard2 title="便捷开发" describe="提供Android/Linux的算法SDK封装，便于集成开发"
                    :src="require('@/assets/images/官网开发素材/算法/2.svg')" />
                <AdvantageCard2 title="适用性广" describe="对车载、无人机、机器人、农业机械等多种典型场景进行特殊优化保证最优性能"
                    :src="require('@/assets/images/官网开发素材/算法/3.svg')" />
                <AdvantageCard2 title="硬件兼容" describe="兼容市面主流gnss/imu产品，提供对应配置文件，算法一键导入"
                    :src="require('@/assets/images/官网开发素材/算法/4.svg')" />
                <AdvantageCard2 title="定制服务" describe="根据特殊需求提供高效可靠贴心的定制化服务"
                    :src="require('@/assets/images/官网开发素材/算法/5.svg')" />
            </div>
        </div>

    </div>

    <div class="bgc div-spacer-h288"></div>

    <div class="bgc h-c">

        <GroupNameCard title="产品组成" describe="Product composition" />

    </div>
    <div class="bgc div-spacer-h80"></div>
    <div class="bgc h-c">
        <div class="bgc v-c">
            <ProductCompositionCard :image="require('@/assets/images/官网开发素材/算法/算法库.jpg')" title="算法库"
                description="标定、时间同步、gnss/imu/odo数据预处理、AHRS航姿计算、松/紧耦合组合导航等多种算法、满足各种高精定位导航开发需求" />
            <ProductCompositionCard :image="require('@/assets/images/官网开发素材/算法/场景配置文件.jpg')" title="场景配置文件"
                description="车载、无人机、机器人/狗、智能农机、船舶定位等多种应用场景专用配置文件" />
            <ProductCompositionCard :image="require('@/assets/images/官网开发素材/算法/SDK说明文件.jpg')" title="SDK说明文档"
                description="算法结构、函数接口说明、调用流程图" />
            <ProductCompositionCard :image="require('@/assets/images/官网开发素材/算法/调用demo演示程序.jpg')" title="调用demo演示程序"
                description="Android/Linux下的demo程序" />


        </div>
    </div>

    <div class="bgc div-spacer-h288"></div>



</template>



<style scoped>

.banner {

    background-image: url("@/assets/images/官网开发素材/banner/算法banner.jpg");
    
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}






.bgc{
    background-color: rgb(245, 245, 247);
}

</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import AdvantageCard2 from '@/components/AdvantageCard2.vue';
import ProductCompositionCard from '@/components/ProductCompositionCard.vue';
import AdvantageCard3 from '@/components/AdvantageCard3.vue';
import GroupNameCard from '@/components/GroupNameCard.vue';

</script>