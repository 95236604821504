/* 2产品服务-卫惯系列/车规/模组 */

<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255);
                    
                    
                    

                    
                    text-align: left;">
                    车规级
                    <br>卫惯产品
                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-16" style="color: rgb(217, 217, 217);
                    
                    
                    
                    
                    
                    text-align: left;">
                    提供卫星拒止下的厘米级定位和导航。
                    车规级技术，超高性能，多元融合算法，在各种复杂环境下提供超稳定、高精度定位服务，满足L2+以上智能驾驶需求。
                </p>


            </div>






        </div>

    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">

        <div class="v-c">



            <div v-show="isMobile">
                <VideoPlayer />
                <div class="div-spacer-h16"></div>
                <div class="div-spacer-h16"></div>
            </div>

            <div v-show="!isMobile" style="display: flex; flex-direction: column; align-items: flex-start">
                <h3 class="text-size-48" style="
                    
                    
                    text-wrap: nowrap;
                    
                    ">
                    车规级卫惯组合产品
                </h3>
                <h3 class="text-size-48" style="
                    
                    text-wrap: nowrap;
                    
                    
                    color: rgb(255, 97, 0);">
                    应用场景介绍
                </h3>
                <ul style="list-style-position: inside;">
                    <li>复杂极端天气</li>
                    <li>城市峡谷</li>
                    <li>高架桥下</li>
                    <li>隧道</li>
                    <li>室内停车场</li>
                </ul>

            </div>

            <div v-show="isMobile" style="display: flex; flex-direction: column; align-items: flex-start">
                <h3 class="text-size-48" style="
                    
                    
                    text-wrap: nowrap;
                    
                    ">
                    车规级卫惯组合产品
                </h3>
                <h3 class="text-size-48" style="
                    
                    text-wrap: nowrap;
                    
                    
                    color: rgb(255, 97, 0);">
                    应用场景介绍
                </h3>
                <p class="text-size-16">复杂极端天气、城市峡谷、<br>高架桥下、隧道、室内停车场</p>


            </div>
            <div v-show="!isMobile" style="max-width: 621px;height: 321px;">
                <VideoPlayer />
            </div>



        </div>

    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">

        <GroupNameCard title="高精定位-整机" describe="High-precision Positioning - P-Box" />

    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c">




        <div class="v-c">
            <div class="g-c-2-1-1" style="justify-items: center;justify-content: center;gap: 16px;">
                <router-link to="/product/automotive/ib11">
                    <ProductCard str1="IB11" str2="IMU-BOX整机"
                        :src="require('@/assets/images/官网开发素材/车规产品/imubox蓝色背景.jpg')" />

                </router-link>

                <router-link to="/product/automotive/p21s">
                    <ProductCard str1="P21S" str2="单天线高精定位整机（乘用车）"
                        :src="require('@/assets/images/官网开发素材/车规产品/P21S蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" />

                </router-link>
                <router-link to="/product/automotive/p22d">
                    <ProductCard str1="P22D" str2="双天线高精定位整机（商用车）"
                        :src="require('@/assets/images/官网开发素材/车规产品/P22D蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" />

                </router-link>
                <router-link to="/product/automotive/cxp-fusion">
                    <ProductCard str1="CXP-FUSION" str2="微型高精定位智联终端"
                        :src="require('@/assets/images/官网开发素材/车规产品/cxpfusion2蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" />

                </router-link>

            </div>

        </div>


    </div>
    <div class="div-spacer-h288"></div>
    <div class="h-c">

        <GroupNameCard title="高精定位-模组/板卡" describe="High-precision Positioning - Module/Board" />

    </div>

    <div class="div-spacer-h48"></div>



    <div class="h-c">

        <div class="v-c">
            <div class="g-c-3-1-1" style="justify-items: center;justify-content: center;gap: 16px;">
                <router-link to="/product/automotive/inm11">
                <ProductCard str1="INM11" str2="INS高精度组合导航定位定向模组"
                    :src="require('@/assets/images/官网开发素材/车规产品/INM11蓝色背景.jpg')"
                    style="min-height: 228px;min-width: 304px;" />

            </router-link>
            <router-link to="/product/automotive/imu/im11">
                <ProductCard str1="IMU模组系列" str2="惯性测量单元系列" :src="require('@/assets/images/官网开发素材/车规产品/imu合集蓝色背景.jpg')"
                    style="min-height: 228px;min-width: 304px;" />

            </router-link>
            <router-link to="/product/automotive/gnm11">
                <ProductCard str1="GNM11" str2="GNSS高精度定位模组" :src="require('@/assets/images/官网开发素材/车规产品/GNM11蓝色背景.jpg')"
                    style="min-height: 228px;min-width: 304px;" />

            </router-link>
            </div>

        </div>
    </div>


    <div class="div-spacer-h288"></div>

</template>



<style scoped>

a{

    width: 100% !important;
    height: 100%;

}
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/隧道1b.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



a{
    width: fit-content;
}


</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import VideoPlayer from '@/components/VideoPlayer.vue';
import GroupNameCard from '@/components/GroupNameCard.vue';
import ProductCard from '@/components/ProductCard.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
</script>