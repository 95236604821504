<!-- 解决方案-无人机 -->

<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255);
                
                
                font-weight: bold;;
                
                
                text-align: left;">

                    行业解决方案

                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(255, 255, 255);
                
                
                
                
                
                text-align: left;">

                    行业场景高精定位定制化解决方案融合了卫星定位技术、惯性定位技术、视觉感知技术、激光雷达技术。为各行业定位需求提供稳定、高效、高精度的信息服务。

                </p>


            </div>





        </div>

    </div>
<div class="div-spacer-h288"></div>

    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/无人机/无人机.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 275px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    无人飞行器
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    无人飞行器是是一种集成了多种先进技术的飞行器，涉及传感器技术、通信技术、信息处理技术、智能控制技术以及航空动力推进技术等，是信息时代高技术含量的产物。
                    无人飞行器被广泛用于航空摄影、包裹递送、农田喷洒监控、基础设施检查、科学研究等领域。
                    无人飞行器的控制系统是核心，是指对无人飞行器进行飞行控制、姿态稳定、导航定位、传感数据采集、通信的多功能系统，主要由机体结构件、动力系统和飞控系统组成。
                </p>
            </div>

        </div>
    </div>
    <div class="div-spacer-h288"></div>


    <div class="h-c" >
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: center;">

                    无人飞行器-行业痛点

                </h3>


                <h3 class="text-size-36" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    定位痛点
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    1、精度稳定性不足：基于导航卫星的无人机定位系统，虽然各大无人机厂商不断优化算法，使精度勉强达到米级，但受地形、天气等客观条件影响，导航卫星信号易受到干扰，导致定位精度稳定性不足。
                    2、信号干扰与遮挡：在城市峡谷、茂密森林等复杂环境中，GPS信号可能受到建筑物、树木等的遮挡，导致定位信号丢失或定位精度下降。
                    3、依赖外部系统：无人机定位高度依赖外部导航系统（如GPS），一旦外部系统出现故障或受到干扰，无人机的定位能力将受到严重影响。
                </p>
                <h3 class="text-size-36" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">

                    姿态痛点

                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    1、姿态控制精度不足：无人机的姿态控制对于飞行稳定性和任务执行至关重要。然而，由于传感器精度、算法性能以及外部环境等因素的影响，无人机的姿态控制精度可能受到限制。这可能导致无人机在飞行过程中出现偏差或不稳定现象。
                    2、抗风能力有限：在强风条件下，无人机的姿态控制会面临更大的挑战。强风可能导致无人机偏离预定航线、姿态失稳甚至坠毁。因此，提高无人机的抗风能力是解决姿态痛点的重要方向之一。
                    复杂环境下的姿态调整：
                    3、在复杂环境中（如城市峡谷、山地等），无人机的姿态调整需要更加精细和快速。这要求无人机的姿态控制系统具备高度的灵活性和适应性，以应对各种突发情况和环境变化。
                    IMU 惯性测量单元/ INS 惯性组合导航系统在无人飞行器姿态控制以及复杂环境下的导航场景中，不依赖外界信号输入即可输出高精度、高稳定性结果，是无人机中的重要组成部分。

                </p>

            </div>

        </div>

    </div>




    <div class="div-spacer-h288"></div>


    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/无人机/无人机2.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                
                
                
                
                
                text-align: left;">
                    产品优势
                </h3>




                









                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    
                    
                    text-align: left;
                    list-style-position: inside;">
                    <li>精度高</li>
                    <li>成熟的姿态算法</li>
                    <li>高性价比</li>
                    <li>高可靠性</li>
                    <li>体积小，方便集成到飞控系统中</li>
                    <li>全面的技术支持服务</li>

                </ul>
            </div>

        </div>
    </div>

    <div class="div-spacer-h288"></div>


    <div class="h-c" >
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            
            
            
            
            
            text-align: center;">


            相关产品

        </h3>

    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c">


        <RelatedScrollingCards3 :products="RelatedScrollingCards3Products" :visibleImages="2" />

    </div>
    <div class="div-spacer-h288"></div>








</template>



<style scoped>
.banner {

    
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("@/assets/images/官网开发素材/banner/行业解决方案banner/无人机3.jpg");
    z-index: -1;
    
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果

import PictureCard from '@/components/PictureCard.vue';
import RelatedScrollingCards3 from '@/components/RelatedScrollingCards3.vue';
const RelatedScrollingCards3Products = [
  {
    imageUrl: require('@/assets/images/官网开发素材/工规产品/cxgy-10 IMU/CXGY-10 IMU.jpg'),
    title: "CXGY-10 IMU",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy10"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-20 VRU/CXGY-20 VRU2.jpg"),
    title: "CXGY-20 VRU",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy20"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-30 AHRS/CXGY-30 AHRS.17.jpg"),
    title: "CXGY-30 AHRS",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy30"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-50 INS-BOX/CXGY-50 INS-BOX双天线pbox.5.jpg"),
    title: "CXGY-50 INS-BOX",
    description: "CXGY-10 系列",
    link: "/product/industry/cxgy50"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/cxgy-110 IMU/CXGY-110 IMU.1.jpg"),
    title: "CXGY-110 IMU",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy110"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-150 INS-BOX双天线/CXGY-150 INS-BOX双天线pbox.3.jpg"),
    title: "CXGY-150 INS-BOX",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy150"
  }
]




</script>