<template>
    <div class="BannerSwiper">
        <swiper :spaceBetween="30" :centeredSlides="true" :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
        }" :pagination="{
            clickable: true,
        }" :navigation="false" :modules="modules" class="mySwiper">



            <swiper-slide class="cxp-fusion">
                <router-link to="/product/automotive/cxp-fusion">
                    <div class="banner">
                        <div class="h-c"
                            style="height: 100%;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125); gap:var(--gap-banner)">
                            <div class="v-c">
                                <h2 class="text-size-64" style="font-weight: bold;">
                                    卫惯天线新组合<br>
                                    高精定位新趋势</h2>
                            </div>
                            <div class="v-c">
                                <div>
                                    <p class="text-size-36">微型高精定位智联终端</p>
                                    <p class="text-size-36" style="font-weight: bold;">CXP-FUSION</p>
                                </div>
                                

                            </div>
                            

                            <div class="v-c">


                                <div style="display: flex;flex-direction: row;justify-content: flex-start;gap: var(--gap-banner);">
                                    <div class="tg453rf">
                                        <img src="@/assets/images/svgs/体积更小.svg">
                                        <p class="text-size-16">体积更小</p>
                                    </div>
                                    <hr style="width: 1px;">
                                    <div class="tg453rf">
                                        <img src="@/assets/images/svgs/形态更活.svg">
                                        <p class="text-size-16">形态更活</p>

                                    </div>
                                    <hr style="width: 1px;">
                                    <div class="tg453rf">
                                        <img src="@/assets/images/svgs/性能更强.svg">
                                        <p class="text-size-16">性能更强</p>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </router-link>
            </swiper-slide>



            <swiper-slide class="mainPic">
                <router-link to="/">
                    <div class="banner">
                        <div class="h-c"
                            style="height: 100%;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);">
                            <div class="v-c">
                                <img src="@/assets/images/svgs/cxrobot白字.svg"
                                    style="max-width: var(--cxrobot-svg-width);">
                            </div>
                            <div class="v-c">
                                <h2 class="text-size-64">诚芯智联</h2>
                            </div>
                            <div class="v-c">
                                <p class="text-size-48">定位感知共创者<br>
                                    智驾事业合伙人</p>
                            </div>

                        </div>

                    </div>

                </router-link>

            </swiper-slide>











        </swiper>





    </div>

</template>





<script setup>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// Import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import AdapButtomBlack from './AdapButtomBlack.vue';

// 定义需要使用的模块
const modules = [Autoplay, Pagination, Navigation];


</script>






<style scoped>
.cxp-fusion {
    background-image: url("@/assets/images/官网开发素材/车规产品/cxfusion/banner.jpg");
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */

    height: 100%
}

.mainPic {
    background-image: url("@/assets/images/官网开发素材/首页/首页.jpg");
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */
    height: 100%
}

.v-c {
    align-items: flex-start !important;
    text-align: left !important;
}


.tg453rf {
    width: fit-content;
    display: flex;
    flex-direction: column;
    max-width: 64px;

}


@media (max-width: 768px) {
    .tg453rf {
        
        
        max-width: 32px;

    }

}
</style>