<template>



    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

                <p class="text-size-64" style="color: rgb(0, 0, 0);
                    
                    

                    
                    
                    text-align: left;">
                    高精度位姿测量装置

                </p>

                <h1 class="text-size-96" style="color: rgb(0, 0, 0);
                    
                    font-weight: bold;
                    text-wrap: nowrap;
                    text-align: left;">
                    CXGY-B130
                </h1>


                <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                    CXGY-B130是一款高精度的位置和姿态测量装置，内置单北斗高精度定位模组，提供高精度定位信息。内置高精度姿态传感器，X,Y 轴测量角度误差 0.1°以内，Z 轴误差 1°以内。
                </p>


            </div>






        </div>

    </div>


    <div v-show="isMobile" class="h-c">
        <div class="div-spacer-h288"></div>
        <div class="v-c">

            <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                CXGY-B130是一款高精度的位置和姿态测量装置，内置单北斗高精度定位模组，提供高精度定位信息。内置高精度姿态传感器，X,Y 轴测量角度误差 0.1°以内，Z 轴误差 1°以内。
            </p>
</div>
        <div class="div-spacer-h288"></div>
    </div>    <div v-show="!isMobile" class="div-spacer-h288"></div>





    <div class="h-c">

        <CombinedTitle title="高精度位姿测量装置" shortTitle="" subTitle="CXGY-B130" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/工规产品/CXBSD131SA/单北斗.3.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                
                    



                    
                    
                    text-align: left;">
                    产品构成
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">




                    <li>BDS（全频北斗定位系统）</li>
                    <li>三轴加速度计 （加计）</li>
                    <li>三轴角速度计 （陀螺）</li>
                    <li>三轴磁力计</li>
                    <li>4G</li>
                    <li>电源电池</li>
                    <li>蘑菇头天线</li>
                    <li>LCD显示屏</li>
                </ul>

            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/工规产品/CXBSD131SA/单北斗.4.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    产品特点
                </h3>
                

                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    


                    
                    text-align: left;">




                    <li>RTK 水平定位精度小于 0.7cm</li>
                    <li>姿态测量准确，X,Y 轴测量角度误差 0.1°以内，Z 轴误差 1°以内</li>
                    <li>防水防尘</li>
                    <li>内置电源支持40小时以上的供电</li>
                </ul>



            </div>


        </div>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <p class="text-size-64" style="text-align: center;
            color: rgb(0, 0, 0);
            
            
            font-weight: bold;

            
            text-align: center;">
            产品性能参数
        </p>

    </div>

    <div class="div-spacer-h80"></div>




    <div class="h-c">
        <div class="v-c">

            <SpecTable :specData="[
    {
      key: '定位性能',
      tableData: [
        {
          title: '跟踪频点',
          contentList: ['BDS: B1I、B1C、B2I、B2a、B2b、B3I']
        },
        {
          title: '单点定位精度',
          contentList: ['水平<=1.5m']
        },
        {
          title: 'RTK定位精度',
          contentList: [
            '水平<=0.7cm+1ppm',
            '高程平<=1.5cm+1ppm'
          ]
        },
        {
          title: '冷启动时间',
          contentList: ['<=30秒']
        },
        {
          title: '热启动',
          contentList: ['<=2秒']
        },
        {
          title: 'RTK初始化时间',
          contentList: ['<=5秒']
        }
      ]
    },
    {
      key: '通讯性能',
      tableData: [
        {
          title: '4G网络',
          contentList: [
            'CAT1,支持频点：LTE-TDD: B34/B38/',
            'B39/B40/B41LTE-FDD: B1/B3/B5/B8'
          ]
        }
      ]
    },
    {
      key: '磁力计',
      tableData: [
        {
          title: '分辨率',
          contentList: ['磁场: 13nT/LSB 角度: 0.0055°/LSB']
        },
        {
          title: '测向精度',
          contentList: ['<=1.5°']
        }
      ]
    }
  ]" />

        </div>
    </div>


    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c" style="justify-content: center;">
            <router-link to="/about/contact" style="text-align: center; width: 100%;">
        <AdapButtomBlack text="获取详细产品信息" />
      </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            font-weight: bold;
            text-align: center;">
            应用场景
        </h3>


    </div>
    <div class="div-spacer-h80"></div>


    <div class="h-c">

        <div v-show="!isMobile" class="v-c">
            <ScrollingCards :items="items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards :items="items" />
        </div>





    </div>


    <div class="div-spacer-h288"></div>





</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner工规产品/科技正圆+CXBSD131SA.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}





</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards from '@/components/ScrollingCards.vue';
import mScrollingCards from '@/components/mScrollingCards.vue';
const items = [
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/无人车4.jpg'), title: '低速无人车', url: '/solution/low-speed-vehicle' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/机器人.jpg'), title: '智能机器人', url: '/solution/smart-robot' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/矿区2.jpg'), title: '工程机械自动化', url: '/solution/engineering-machinery' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/智慧农机.jpg'), title: '自动化农机', url: '/solution/agricultural-machinery' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/无人机3.jpg'), title: '无人飞行器', url: '/solution/uav' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/港口.jpg'), title: '港口船舶', url: '/solution/port-shipping' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/自动驾驶.jpg'), title: '智能驾驶汽车', url: '/solution/smart-driving' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/测绘1.jpg'), title: '智慧测绘仪器', url: '/solution/smart-surveying' },

];import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import FeatureCard from '@/components/FeatureCard.vue';
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtom from '@/components/AdapButtom.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>